import ProForm, {ProFormText, ProFormTextArea} from '@ant-design/pro-form';
import {FrontendSupportService} from '@common/api';
import {Button} from 'antd';
import {
  CommonManagerEdit,
  CommonManagerEditProps,
} from 'components/Common/Manager/Edit';
import {useAuthorize} from 'core/Authorize';
import {useApi} from 'hooks/useApi';

export const BackendSupportReport: React.FC<CommonManagerEditProps> = props => {
  const {user} = useAuthorize<any>();
  const add = useApi(FrontendSupportService.add, {successMessage: '回報成功'});
  return (
    <CommonManagerEdit
      initialValues={user || {}}
      trigger={<Button>回報問題</Button>}
      onFinish={async values => {
        await add({requestBody: values});
      }}
      {...props}
    >
      <ProFormText
        label="聯絡信箱"
        name="email"
        required
        rules={[{required: true}]}
      />
      <ProFormTextArea
        label="回報內容"
        name="content"
        required
        rules={[{required: true}]}
      />
    </CommonManagerEdit>
  );
};
