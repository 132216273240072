import {useEffect, useRef, useState} from 'react';
import styled from 'styled-components';
import cls from 'classnames';

const $StickyToolbar = ({className, children}: any) => {
  const divRef = useRef<HTMLDivElement>(null);
  const [isSticky, setIsSticky] = useState(false);
  useEffect(() => {
    const checkPosition = () => {
      if (divRef.current) {
        const {bottom} = divRef.current.getBoundingClientRect();
        const bottomPadding = bottom - window.innerHeight;
        setIsSticky(bottomPadding === 0);
      }
    };
    window.addEventListener('scroll', checkPosition);
    checkPosition();
    return () => {
      window.removeEventListener('scroll', checkPosition);
    };
  }, []);

  return (
    <div className={cls(className, {isSticky})} ref={divRef}>
      {children}
    </div>
  );
};

export const StickyToolbar = styled.div`
  position: sticky;
  bottom: 0px;
  background-color: #fafafa;
  display: flex;
  justify-content: center;
  left: 0px;
  right: 0px;
  width: 100%;
  z-index: 10;
  margin-top: 12px;
  padding: 12px;
  transition: 0.5s;
  border: 2px solid transparent;
  border-bottom: none !important;
  &.isSticky {
    /* box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.5); */
    border: 2px solid #ddd;
  }
`;
