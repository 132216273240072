import { request as __request } from '../core/request';
export class PublicConfigService {
    /**
     * @returns any Success
     * @throws ApiError
     */
    static getPublicConfig() {
        return __request({
            method: 'GET',
            path: `/api/config`,
        });
    }
}
