import {Theme} from 'styled-system';
/** 使用 Ant Colors 做為基礎色調 */
import {
  blue,
  red,
  volcano,
  orange,
  gold,
  yellow,
  lime,
  green,
  cyan,
  geekblue,
  purple,
  magenta,
  grey,
} from '@ant-design/colors';

/** Styled-System Theme Spec https://styled-system.com/theme-specification/  */
export const defaultTheme: Theme = {
  space: [4, 8, 12, 16],
  fontSizes: [],
  colors: {
    primary: blue.primary || '',
    danger: red.primary || '',
    warning: gold.primary || '',
    info: cyan.primary || '',
    success: green.primary || '',
    blue,
    red,
    volcano,
    orange,
    gold,
    yellow,
    lime,
    green,
    cyan,
    geekblue,
    purple,
    magenta,
    grey,
    linear: {
      start: '#822CA4',
      end: '#AEDEFF',
    },
  },
  fonts: [],
  fontWeights: [],
  lineHeights: [],
  letterSpacings: [],
  sizes: [],
  borders: [],
  borderStyles: [],
  borderWidths: [],
  radii: [],
  shadows: [],
  zIndices: [],
};
