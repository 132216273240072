import {ModalForm, ModalFormProps, ProFormInstance} from '@ant-design/pro-form';
import React, {useRef} from 'react';

export const CustomModalForm = (
  props: React.PropsWithChildren<ModalFormProps>
) => {
  const formRef = useRef<ProFormInstance<Record<string, any>> | undefined>();

  return (
    <ModalForm
      {...props}
      formRef={formRef}
      onVisibleChange={visible => {
        if (!visible) {
          formRef.current?.resetFields();
        }
        props.onVisibleChange && props.onVisibleChange(visible);
      }}
    />
  );
};
