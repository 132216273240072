import {
  ProFormDependency,
  ProFormGroup,
  ProFormSwitch,
  ProFormText,
} from '@ant-design/pro-form';
import {FrontendMeService} from '@common/api';
import {Alert} from 'antd';
import {CustomModalForm} from 'components/Common/Form/ModalForm';
import {useAuthorize} from 'core/Authorize';
import {useApi} from 'hooks/useApi';
import {useVIP} from 'hooks/useVIP';
import React from 'react';

export const BackendEditProfile = ({
  children,
}: React.PropsWithChildren<any>) => {
  const {isEnabled} = useVIP();
  const {onFetch, user} = useAuthorize<any>();
  const editProfile = useApi(FrontendMeService.editProfile, {
    successMessage: '修改個人資料成功',
  });
  return (
    <CustomModalForm
      trigger={children}
      title="修改個人資料"
      initialValues={user}
      onFinish={async values => {
        await editProfile({requestBody: values});
        await onFetch?.();
        return true;
      }}
    >
      <ProFormText name="nickname" label="暱稱" rules={[{required: true}]} />
      <ProFormText name="email" label="Email" rules={[{required: true}]} />

      {!isEnabled && (
        <Alert
          message={'以下設定再啟用VIP方案後即可開通'}
          type="warning"
        ></Alert>
      )}
      <ProFormGroup>
        <ProFormSwitch
          label="啟用通知"
          tooltip="當錯誤次數達到上限時，啟用簡訊通知"
          name="isSendNotify"
          disabled={!isEnabled}
        ></ProFormSwitch>
        <ProFormDependency name={['isSendNotify']}>
          {({isSendNotify}) => (
            <ProFormText
              label="手機號碼"
              name="phone"
              disabled={!isSendNotify || !isEnabled}
              rules={[{required: isSendNotify}]}
            />
          )}
        </ProFormDependency>
      </ProFormGroup>
    </CustomModalForm>
  );
};
