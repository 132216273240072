import { request as __request } from '../core/request';
export class AuthService {
    /**
     * 透過Email重設密碼
     * @returns any Success
     * @throws ApiError
     */
    static resetPassword({ requestBody, }) {
        return __request({
            method: 'POST',
            path: `/api/auth/reset_password`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * 登入
     * @returns any Success
     * @throws ApiError
     */
    static login({ requestBody, }) {
        return __request({
            method: 'POST',
            path: `/api/auth`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * 取得登入狀態
     * @returns any Success
     * @throws ApiError
     */
    static getProfile() {
        return __request({
            method: 'GET',
            path: `/api/auth`,
        });
    }
    /**
     * 登出
     * @returns any Success
     * @throws ApiError
     */
    static logout() {
        return __request({
            method: 'DELETE',
            path: `/api/auth`,
        });
    }
    /**
     * 註冊
     * @returns any Success
     * @throws ApiError
     */
    static register({ requestBody, }) {
        return __request({
            method: 'POST',
            path: `/api/auth/register`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }
}
