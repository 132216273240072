import styled from 'styled-components';
import {ProFormText} from '@ant-design/pro-form';
import {Button, InputProps} from 'antd';
import {ProFormFieldItemProps} from '@ant-design/pro-form/lib/interface';
import {FrontendButton} from 'components/Frontend/Button';

const LoginInputComp = (
  props: ProFormFieldItemProps<InputProps> & {isPassword?: boolean}
) => {
  if (props.isPassword) {
    return (
      <ProFormText.Password
        formItemProps={{className: props.className}}
        {...props}
      />
    );
  }
  return (
    <ProFormText formItemProps={{className: props.className}} {...props} />
  );
};

export const LoginInput = styled(LoginInputComp)`
  label {
    color: #fff !important;
  }
  .ant-input-affix-wrapper {
    padding: 12px !important;
    border-radius: 24px !important;
    input {
      padding-left: 24px;
    }
    overflow: hidden;
  }
`;

export const LoginTitle = styled.h1`
  color: #fff;
  text-align: center;
`;

export const LoginSubTitle = styled.h2`
  color: #fff;
  text-align: center;
`;

export const LoginButton = FrontendButton;
