import ProForm, {ProFormText} from '@ant-design/pro-form';
import {CommonFormDraft} from 'components/Common/Form/Draft';
import {
  CommonManagerEdit,
  CommonManagerEditProps,
} from 'components/Common/Manager/Edit';
import {useApi} from 'hooks/useApi';
import {BackendFaqService} from '@common/api';

export const BackendFAQEdit = (props: CommonManagerEditProps) => {
  const add = useApi(BackendFaqService.add);
  const edit = useApi(BackendFaqService.update);
  return (
    <CommonManagerEdit
      {...props}
      onValuesChange={console.log}
      // initialValues={props.data}
      request={async () => {
        return props.data || {};
      }}
      onFinish={async values => {
        if (props.data) {
          await edit({id: props.data.id, requestBody: values});
        } else {
          await add({requestBody: values});
        }
      }}
    >
      <ProFormText label="標題" name="title"></ProFormText>
      <ProForm.Item label="內容" name="content">
        <CommonFormDraft />
      </ProForm.Item>
    </CommonManagerEdit>
  );
};
