import {PepayFormBody} from '@common/api';
import {Typography} from 'antd';
import {useEffect, useRef} from 'react';
export type BackendPepayFormProps = {
  data: PepayFormBody;
};
export const BackendPepayForm: React.FC<BackendPepayFormProps> = ({data}) => {
  const ref = useRef<HTMLFormElement>(null);
  useEffect(() => {
    setTimeout(() => {
      ref.current?.submit();
    }, 3000);
  }, [data]);
  return (
    <>
      <Typography.Title>
        即將跳轉至付款畫面，請勿重新整理或是關閉網頁。
      </Typography.Title>
      <form
        ref={ref}
        method="POST"
        action="https://gate.pepay.com.tw/pepay/paysel_amt.php"
      >
        {Object.keys(data).map(key => (
          <input type="hidden" value={(data as any)[key]} name={key} />
        ))}
      </form>
    </>
  );
};
