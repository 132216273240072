import {ProFormDigit, ProFormRadio, ProFormText} from '@ant-design/pro-form';
import {BackendConfigService, ConfigEntityListCrudList} from '@common/api';
import {Radio} from 'antd';
import {CustomButton} from 'components/Common/Button';
import {
  CommonManagerEdit,
  CommonManagerEditProps,
} from 'components/Common/Manager/Edit';
import {CommonModalForm} from 'components/Common/ModalForm';
import {useApi} from 'hooks/useApi';
import {useCallback} from 'react';

export const BackendConfigEdit = (
  props: CommonManagerEditProps<ConfigEntityListCrudList>
) => {
  const {data} = props;
  const update = useApi(BackendConfigService.update);

  const renderControls = useCallback(() => {
    if (data) {
      switch (data?.key) {
        case 'RECAPTCHA_PLATFORM':
          return (
            <ProFormRadio.Group
              name="value"
              label={data.name}
              options={[
                {value: 'IMAGE_TYPERZ', label: 'ImageTyperz'},
                {value: 'CAPTCHA_CODER', label: 'captchaCoder'},
              ]}
            ></ProFormRadio.Group>
          );
        case 'ERROR_STOP_LIMIT':
        case 'ERROR_BLACK_LIMIT':
          return <ProFormDigit min={0} name={'value'} label={data.name} />;
        case 'ROBOT_URL':
          return (
            <ProFormText
              rules={[{type: 'url'}]}
              name="value"
              label={data.name}
            />
          );
        default:
          return <ProFormText name="value" label={data.name} />;
      }
    }
    return null;
  }, [data]);
  return (
    <CommonManagerEdit
      {...props}
      request={async () => {
        return data || {};
      }}
      onFinish={async values => {
        await update({
          id: data?.id as any,
          requestBody: values,
        });
      }}
    >
      {renderControls()}
    </CommonManagerEdit>
  );
};
