import React from 'react';
import {useEffect} from 'react';
import {useContext} from 'react';

export type CoreAuthorizeProps<T = any> = {
  /** 目前登入的使用者 (null or undefined 視為未登入) */
  user?: T;
  onFetch?: () => Promise<void>;
};

export const CoreAuthorizeContext = React.createContext<CoreAuthorizeProps>({});

/** 用於處理使用者授權, 以及負責向底下的元件傳遞user資料 */
export const CoreAuthorize: React.FC<CoreAuthorizeProps> = ({
  children,
  onFetch,
  ...props
}) => {
  useEffect(() => {
    if (onFetch) {
      onFetch();
    }
  }, [onFetch]);

  return (
    <CoreAuthorizeContext.Provider
      value={{
        ...props,
        onFetch,
      }}
    >
      {children}
    </CoreAuthorizeContext.Provider>
  );
};

export const useAuthorize = <T extends any>() =>
  useContext(CoreAuthorizeContext) as CoreAuthorizeProps<T>;
