import { request as __request } from '../core/request';
export class BackendStatsService {
    /**
     * @returns OrderStatsRes Success
     * @throws ApiError
     */
    static getOrderStats({ startAt, endAt, sortKey, sortType, download, }) {
        return __request({
            method: 'GET',
            path: `/api/backend/stats/orders`,
            query: {
                'startAt': startAt,
                'endAt': endAt,
                'sortKey': sortKey,
                'sortType': sortType,
                'download': download,
            },
        });
    }
}
