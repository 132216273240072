import ProForm, {ProFormInstance, ProFormProps} from '@ant-design/pro-form';
import {Space} from 'antd';
import _ from 'lodash';
import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {StickyToolbar} from '../Form/StickyToolbar';
export type FormProps = ProFormProps;

export const CustomProForm = ({
  submitter,
  children,
  ...formProps
}: React.PropsWithChildren<FormProps>) => {
  const formRef = useRef<ProFormInstance>();

  return (
    <ProForm
      {...formProps}
      submitter={
        submitter !== false
          ? {
              render: (props, doms) => (
                <StickyToolbar>
                  <Space style={{width: '100%', justifyContent: 'center'}}>
                    {doms}
                  </Space>
                </StickyToolbar>
              ),
              ...submitter,
            }
          : false
      }
    >
      {children}
    </ProForm>
  );
};
