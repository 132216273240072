import ProForm, {ProFormText, ProFormFieldProps} from '@ant-design/pro-form';
import {Input, InputProps} from 'antd';
import _, {padEnd} from 'lodash';
import moment from 'moment';

type CommonTimeInputProps = Omit<InputProps, 'onChange'> & {
  onChange: (time: string) => void;
};

export const CommonTimeField = (
  props: ProFormFieldProps<CommonTimeInputProps>
) => {
  return (
    <ProForm.Item {...(props as any)}>
      <CommonTimeInput {...props.fieldProps} />
    </ProForm.Item>
  );
};

export const CommonTimeInput = ({
  value: prevValue,
  onChange,
  ...props
}: CommonTimeInputProps) => {
  return (
    <Input
      {...props}
      value={prevValue}
      placeholder="HH:mm"
      onBlur={e => {
        if (typeof e.target.value === 'string') {
          const [h, m = ''] = e.target.value.split(':');
          if (h && h.length === 2) {
            onChange(`${h}:${padEnd(m, 2, '0')}`);
          } else {
            onChange('');
          }
        }
      }}
      onChange={e => {
        if (typeof e.target.value === 'string') {
          const value = e.target.value;
          if (
            typeof prevValue === 'string' &&
            prevValue.length > value.length
          ) {
            onChange(value);
          } else {
            try {
              let result = value;
              if (typeof value === 'string') {
                result = '';
                const [h, m] = value.split(':');
                if (h) {
                  if (h.length === 1 && parseInt(h) > 2) {
                    throw new Error();
                  } else if (h.length === 2 && parseInt(h) > 23) {
                    throw new Error();
                  } else {
                    result += h;
                    if (h.length === 2) {
                      result += ':';
                    }
                  }
                }

                if (m) {
                  if (
                    (m.length === 1 && parseInt(m) > 6) ||
                    (m.length === 2 && parseInt(m) > 60)
                  ) {
                    throw new Error();
                  }
                  result += m.slice(0, 2);
                }
              }
              onChange && onChange(result);
            } catch (err) {
              //time spce error
            }
          }
        }
      }}
    />
  );
};
