import {UserOutlined} from '@ant-design/icons';
import {AuthService, FrontendMeService} from '@common/api';
import {Button, Dropdown, Menu, Modal} from 'antd';
import {BaseLayout} from 'components/Common/Layout';
import {Login} from 'components/Login';
import {useAuthorize} from 'core/Authorize';
import {PageTemplateProps} from 'core/DynamicPages';
import {createPages} from 'core/DynamicPages/createRoutes';
import {useApi} from 'hooks/useApi';
import {useAsyncMemo} from 'hooks/useAsync';
import fp from 'lodash/fp';
import React, {useCallback, useEffect, useMemo} from 'react';
import {Link, useHistory} from 'react-router-dom';
import {BackendEditPassword} from './User/EditPassword';
import {BackendEditProfile} from './User/EditProfile';
import {BackendVerifyEmail} from './User/VerifyEmail';

export const BackendLayout = ({
  page,
  children,
}: React.PropsWithChildren<PageTemplateProps>) => {
  const history = useHistory();
  const logout = useApi(AuthService.logout);
  const getQuota = useApi(FrontendMeService.quota);
  const {user, onFetch} = useAuthorize<any>();

  const quota = useAsyncMemo(async () => {
    if (user) {
      const res = await getQuota();
      return res.data;
    }
    return null;
  }, [user]);

  useEffect(() => {
    if (
      quota.result !== null &&
      quota.result !== undefined &&
      quota.result <= 0
    ) {
      Modal.confirm({
        content: '您的儲值點數已經用盡，是否要前往儲值？',
        onOk: () => {
          history.push('/admin/point');
        },
      });
    }
  }, [quota.result]);

  const onLogout = useCallback(async () => {
    await logout();
    if (onFetch) {
      await onFetch();
    }
    history.push('/');
  }, []);

  const filteredPage = useMemo(() => {
    if (user && user.role !== 'ADMIN') {
      return fp.pipe(fp.update('pages', fp.omit(['manager'])))(page);
    }
    return page;
  }, [page, user]);

  if (!user) {
    return <Login onLoginSuccess={() => {}} isFull />;
  }
  return (
    <BaseLayout
      page={filteredPage}
      logo={<img src="/static/images/logo_s.png" />}
      title="捷克置頂系統"
      menuHeaderRender={(logo, title) =>
        title ? (
          <>
            {logo}
            {title}
          </>
        ) : undefined
      }
      rightContentRender={props => (
        <Dropdown
          trigger={['click']}
          overlay={
            <Menu>
              <BackendEditProfile>
                <Menu.Item>修改個人資料</Menu.Item>
              </BackendEditProfile>
              <BackendEditPassword>
                <Menu.Item>修改密碼</Menu.Item>
              </BackendEditPassword>
              <Link to="/">
                <Menu.Item>返回首頁</Menu.Item>
              </Link>
              <Menu.Item onClick={onLogout}>登出系統</Menu.Item>
            </Menu>
          }
        >
          <Button type="link" icon={<UserOutlined />}>
            {user.username} 歡迎您！{' '}
            {user.role === 'TRIAL_USER' && (
              <span style={{color: 'red'}}>(試用會員)</span>
            )}
            {user.emailVerify ? (
              `(剩餘次數：${quota.result})`
            ) : (
              <BackendVerifyEmail>
                <a
                  style={{color: 'red'}}
                  onClick={e => {
                    e.stopPropagation();
                  }}
                  href="javascript:void(0)"
                >
                  (尚未驗證Email)
                </a>
              </BackendVerifyEmail>
            )}
          </Button>
        </Dropdown>
      )}
    >
      {createPages(filteredPage.pages, ['/admin'], filteredPage)}
    </BaseLayout>
  );
};
