import {BackendPlanService} from '@common/api';
import {Modal} from 'antd';
import {CommonManager} from 'components/Common/Manager';
import {CommonManagerEdit} from 'components/Common/Manager/Edit';
import {CommonTable} from 'components/Common/Table';
import {useApi} from 'hooks/useApi';
import {genRequest} from 'libs/genRequest';

import withProps from 'recompose/withProps';
import {BackendPlanEdit} from './Edit';

const DetailLink = withProps({
  children: '使用記錄',
})(CommonManager.RowButton);

export const BackendPlanManager = () => {
  const findAll = useApi(BackendPlanService.findAll);
  const del = useApi(BackendPlanService.delete);
  const edit = useApi(BackendPlanService.update);
  return (
    <CommonManager
      headerActions={[BackendPlanEdit]}
      params={{
        sortKey: 'title',
        sortType: 'ASC',
      }}
      rowActions={(row, act) => {
        let isEffect = false;
        if (row.effectAt !== null && new Date(row.effectAt) < new Date()) {
          isEffect = true;
        }
        const result: any[] = [BackendPlanEdit];
        if (!isEffect) {
          result.push(
            <CommonManager.DeleteButton
              onClick={async () => {
                Modal.confirm({
                  content: '確定要返回到上一個版本嗎？',
                  onOk: async () => {
                    await del({
                      id: row.id,
                      softDelete: false,
                    });
                    act?.reload();
                  },
                });
              }}
            >
              復原到上一版
            </CommonManager.DeleteButton>
          );
        } else {
          result.push(
            <CommonManager.DeleteButton
              onClick={async () => {
                Modal.confirm({
                  content: '確定要永久刪除此方案？',
                  onOk: async () => {
                    await edit({
                      id: row.id,
                      requestBody: {
                        isEnabled: false,
                        effectAt: new Date().toString(),
                      },
                    });
                    await del({
                      id: row.id,
                    });
                    act?.reload();
                  },
                });
              }}
            >
              刪除
            </CommonManager.DeleteButton>
          );
        }
        return result;
      }}
      request={genRequest(findAll)}
      columns={[
        CommonTable.Utils.Sorter({
          title: '類型',
          dataIndex: 'type',
          valueEnum: {
            SHOP_ITEM: '商品',
            GIFT: '註冊贈送',
            ADMIN: '後台專用',
          },
        }),
        CommonTable.Utils.Sorter({
          title: '方案名稱',
          dataIndex: 'title',
        }),

        CommonTable.Utils.Sorter({
          title: '點數',
          dataIndex: 'point',
          valueType: 'digit',
        }),
        CommonTable.Utils.Sorter({
          title: '贈送點數',
          dataIndex: 'free',
          valueType: 'digit',
        }),
        CommonTable.Utils.Sorter({
          title: '每次執行扣除點數',
          dataIndex: 'fee',
          valueType: 'digit',
        }),
        CommonTable.Utils.Sorter({
          title: '價格',
          dataIndex: 'price',
          valueType: 'digit',
        }),
        CommonTable.Utils.Sorter({
          title: '開始執行日',
          dataIndex: 'effectAt',
          valueType: 'date',
        }),
        CommonTable.Utils.Sorter({
          title: '狀態',
          dataIndex: 'isEnabled',
          valueEnum: {
            true: '啟用',
            false: '停用',
          },
        }),
        CommonTable.Utils.Sorter({title: '版本', dataIndex: 'version'}),
      ]}
    />
  );
};
