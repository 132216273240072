import {useCallback, useEffect, useState} from 'react';
import fp from 'lodash/fp';
import {stateProps} from 'recompose';
export const useTimeLock = () => {
  const [state, setState] = useState<number>(0);

  useEffect(() => {
    if (state > 0) {
      setTimeout(() => {
        setState(fp.subtract(fp.__, 1));
      }, 1000);
    }
  }, [state]);

  return [state, setState] as [typeof state, typeof setState];
};
