import {
  BackendUserPointCardService,
  FrontendUserPointCardService,
} from '@common/api';
import {CommonManager} from 'components/Common/Manager';
import {CommonTable} from 'components/Common/Table';
import {useApi} from 'hooks/useApi';
import {genRequest} from 'libs/genRequest';
import {props} from 'lodash/fp';
import React from 'react';
import withProps from 'recompose/withProps';
import {VerifyEmail} from '../VerifyEmail';
import {BackendPointCardAdd} from './Add';
import {BackendPointCardBuy} from './Buy';

const DetailLink = withProps((props: any) => ({
  children: '使用記錄',
  state: {
    title: `${props.data.orderId} 使用記錄`,
  },
}))(CommonManager.RowButton);

export type BackendPointCardManagerProps = {
  userId?: string;
};

export const BackendPointCardManager = ({
  userId,
}: React.PropsWithChildren<BackendPointCardManagerProps>) => {
  const find = useApi(
    userId
      ? BackendUserPointCardService.findAll
      : FrontendUserPointCardService.findAll
  );
  return (
    <VerifyEmail ignore={!!userId}>
      <CommonManager
        headerActions={
          userId
            ? [<BackendPointCardAdd userId={userId} />]
            : [BackendPointCardBuy]
        }
        rowActions={userId ? [] : [DetailLink]}
        request={genRequest(find)}
        params={{userId}}
        columns={[
          CommonTable.Utils.Sorter({
            title: '訂單狀態',
            dataIndex: 'status',
            valueEnum: {
              CREATE: '等待付款',
              RECEIVE1: '等待付款',
              RECEIVE2: '付款成功',
            },
          }),
          CommonTable.Utils.Sorter({
            title: '點數總額',
            dataIndex: 'point',
            valueType: 'digit',
          }),
          CommonTable.Utils.Sorter({
            title: '已使用',
            dataIndex: 'usePoint',
            valueType: 'digit',
          }),
          CommonTable.Utils.Sorter({
            title: '新增時間',
            dataIndex: 'createdAt',
            valueType: 'dateTime',
          }),
          CommonTable.Utils.Sorter({
            title: '購買內容',
            dataIndex: 'orderItem',
          }),
          CommonTable.Utils.Sorter({title: '訂單編號', dataIndex: 'orderId'}),
        ]}
      />
    </VerifyEmail>
  );
};
