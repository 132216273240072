import {PublicConfigService} from '@common/api';
import fp from 'lodash/fp';
import {useCallback} from 'react';
import {useAsyncMemo} from './useAsync';

export type PublicConfigKey = 'ERROR_STOP_LIMIT' | 'ERROR_BLACK_LIMIT';

export const useConfig = () => {
  const config = useAsyncMemo(async () => {
    const res = await PublicConfigService.getPublicConfig();
    if (res.data) {
      return fp.pipe(fp.keyBy('key'), fp.mapValues(fp.get('value')))(res.data);
    }
    return {};
  }, []);

  const getConfig = useCallback(
    (key: PublicConfigKey) => {
      return config.result?.[key];
    },
    [config.result]
  );
  return {
    getConfig,
  };
};
