import {BackendUserService} from '@common/api';
import {Modal} from 'antd';
import {CustomButton} from 'components/Common/Button';
import {CommonManager} from 'components/Common/Manager';
import {CommonTable} from 'components/Common/Table';
import {useApi} from 'hooks/useApi';
import {genRequest} from 'libs/genRequest';
import {BackendUserEdit} from './Edit';

export function BatchRecovery({selectedKeys, action}: any) {
  const recovery = useApi(BackendUserService.batchRecovery);
  return (
    <CustomButton
      disabled={selectedKeys.length === 0}
      type="primary"
      onClick={() => {
        Modal.confirm({
          content: '是否要還原所選的項目？',
          onOk: async () => {
            await recovery({
              requestBody: {idList: selectedKeys},
            });
            action.clearSelected();
            action.reload();
          },
        });
      }}
    >
      批次還原
    </CustomButton>
  );
}

export const BackendDeletedUserList = () => {
  const del = useApi(BackendUserService.delete);
  return (
    <CommonManager
      headerActions={[BatchRecovery]}
      request={genRequest(BackendUserService.findAll)}
      rowActions={[BackendUserEdit]}
      rowSelection={{}}
      params={{withDeleted: true, sortKey: 'deletedAt', sortType: 'DESC'}}
      columns={[
        CommonTable.Utils.Sorter({
          title: '帳號',
          dataIndex: 'username',
        }),
        CommonTable.Utils.Sorter({
          title: '名稱',
          dataIndex: 'nickname',
        }),
        CommonTable.Utils.Sorter({
          title: '權限',
          dataIndex: 'role',
          valueEnum: {
            USER: '一般使用者',
            ADMIN: '管理員',
            TRIAL_USER: '試用會員',
          },
        }),
        CommonTable.Utils.Sorter({
          title: '刪除時間',
          dataIndex: 'deletedAt',
          valueType: 'dateTime',
        }),
      ]}
    />
  );
};
