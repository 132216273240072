import ProForm from '@ant-design/pro-form';
import {BackendHtmlContentService} from '@common/api';
import {CustomProForm} from 'components/Common/Form';
import {CommonFormDraft} from 'components/Common/Form/Draft';
import {useApi} from 'hooks/useApi';
import {useState} from 'react';

export const BackendContentEditor = () => {
  const findOne = useApi(BackendHtmlContentService.findOne);
  const update = useApi(BackendHtmlContentService.update, {
    successMessage: '更新成功',
  });
  const [lastUpdate, setLastUpdate] = useState(new Date());
  return (
    <CustomProForm
      params={{lastUpdate}}
      request={async () => {
        const res = await findOne({id: '1'});
        return res.data || {};
      }}
      onFinish={async values => {
        await update({
          id: '1',
          requestBody: values,
        });
        setLastUpdate(new Date());
      }}
    >
      <ProForm.Item label="內容" name="content">
        <CommonFormDraft />
      </ProForm.Item>
    </CustomProForm>
  );
};
