import {BackendFaqService} from '@common/api';
import {CommonManager} from 'components/Common/Manager';
import {CommonTable} from 'components/Common/Table';
import {useApi} from 'hooks/useApi';
import {genRequest} from 'libs/genRequest';
import withProps from 'recompose/withProps';
import {BackendFAQEdit} from './Edit';

const DetailLink = withProps({
  children: '使用記錄',
})(CommonManager.RowButton);

export const BackendFAQManager = () => {
  const findAll = useApi(BackendFaqService.findAll);
  const del = useApi(BackendFaqService.delete);
  return (
    <CommonManager
      headerActions={[BackendFAQEdit]}
      rowActions={[BackendFAQEdit]}
      request={genRequest(findAll)}
      columns={[
        CommonTable.Utils.Sorter({
          title: '標題',
          dataIndex: 'title',
        }),
      ]}
      onDelete={async ({id}) => {
        await del({id});
      }}
    />
  );
};
