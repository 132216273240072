import {BackendUserService} from '@common/api';
import {Modal} from 'antd';
import {CustomButton} from 'components/Common/Button';

export function BatchDelete({selectedKeys, action}: any) {
  return (
    <CustomButton.Del
      disabled={selectedKeys.length === 0}
      onClick={() => {
        Modal.confirm({
          content: '是否要刪除所選的項目？',
          onOk: async () => {
            await BackendUserService.batchDelete({
              requestBody: {idList: selectedKeys},
            });
            action.clearSelected();
            action.reload();
          },
        });
      }}
    >
      批次刪除
    </CustomButton.Del>
  );
}
