import {BackendSupportService} from '@common/api';
import {Badge} from 'antd';
import {useAuthorize} from 'core/Authorize';
import {useApi} from 'hooks/useApi';
import {useEffect, useState} from 'react';

export const WatchUnread = () => {
  const {user} = useAuthorize<any>();
  const [unreadSupport, setUnreadSupport] = useState(0);
  const getList = useApi(BackendSupportService.findAll);
  useEffect(() => {
    let timer: any = null;
    if (user && user.role === 'ADMIN') {
      const getCount = () => {
        getList({
          page: {pageSize: 0, current: 1},
          where: {status: 'OPEN'},
        }).then(res => {
          setUnreadSupport(res.total || 0);
          timer = setTimeout(() => {
            getCount();
          }, 10000);
        });
      };

      getCount();
      return () => {
        clearInterval(timer);
      };
    }
  }, [user]);
  return <Badge count={unreadSupport}></Badge>;
};
