import {UserOutlined} from '@ant-design/icons';
import {JKUserManager} from 'components/Backend/JKUser/Manager';

export const title = '捷克會員管理';

export const orderNum = 1;

export const icon = <UserOutlined />;

export default JKUserManager;
