import {FrontendMeService} from '@common/api';
import {useApi} from './useApi';
import {useAsyncMemo} from './useAsync';

export const useVIP = () => {
  const getExpired = useApi(FrontendMeService.getMeOffDatePlan);

  const isEnabled = useAsyncMemo(async () => {
    try {
      const expired = await getExpired();
      return expired.data !== null;
    } catch (err) {
      return false;
    }
  }, []);
  return {
    isEnabled: isEnabled.result,
  };
};
