import {PlanEntityListFrontendList} from '@common/api';
import React from 'react';
import styled from 'styled-components';

export const FrontendHomeBanner = styled.div`
  background-image: url('/static/images/bg.png');
  background-size: cover;
  background-position: center center;
  color: #fff;
  size: 18px;
`;

export const FrontendHomeTitle = styled.h1`
  font-size: 36px;
  color: #fff;
`;

export const FrontendHomeDesc = styled.h1`
  color: #fff;
  font-size: 20px;
`;

export const FrontendPlanCardWrap = styled.div`
  background-color: #fff;
  border-radius: 24px;
  padding: 12px 24px;
  margin: 12px;
  height: 88%;
  h1 {
    color: #069aa6;
    margin: 0px 0px;
  }
  > div {
    font-size: 16px;
    margin: 0px;
    color: #000;
    > div {
      /* display: flex; */
      /* flex-wrap: wrap; */
      /* justify-content: space-between; */
      /* align-items: baseline; */
    }
    span {
      font-size: 22px;
      margin: 0px 8px;
    }
  }
`;

export const FrontendPlanCard = ({
  data,
}: React.PropsWithChildren<{data: PlanEntityListFrontendList}>) => {
  return (
    <FrontendPlanCardWrap>
      <h1>{data.title}</h1>
      <div style={{display: 'flex', flexWrap: 'wrap'}}>
        <div>
          儲值<span>{data.price}</span>元可得<span>{data.point}</span>點
          {data.free ? `,再送${data.free}點` : ''}
          ,執行一次扣<span>{data.fee}</span>點
        </div>
      </div>
    </FrontendPlanCardWrap>
  );
};

export const FrontendSectionTitle = styled.div`
  text-align: center;
  font-size: 30px;
  margin: 12px 0px;
  display: flex;
  align-items: center;
  flex-direction: column;
  &:after {
    content: '';
    width: 60px;
    border-bottom: 3px solid #822ca4;
    margin: 8px 0px;
  }
`;
