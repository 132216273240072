import {ProFormDigit} from '@ant-design/pro-form';
import {BackendUserPointCardService} from '@common/api';
import {CustomButton} from 'components/Common/Button';
import {CommonManagerEdit} from 'components/Common/Manager/Edit';
import {useApi} from 'hooks/useApi';
import {useCallback} from 'react';
import {BackendPlanSelect} from '../Plan/Select';

export const BackendPointCardAdd = ({userId, action}: any) => {
  const add = useApi(BackendUserPointCardService.add);

  return (
    <CommonManagerEdit
      onFinish={async values => {
        const {planId, count} = values;
        await add({
          requestBody: {
            planId,
            count,
            userId,
            status: 'RECEIVE2',
            orderItem: '管理員新增',
          },
        });
      }}
      trigger={<CustomButton.Add>加點數</CustomButton.Add>}
    >
      <BackendPlanSelect
        isAdmin
        rules={[{required: true, message: '請選擇方案'}]}
      />
      <ProFormDigit
        name="count"
        rules={[{required: true}]}
        label="張數"
        min={1}
        initialValue={1}
      />
    </CommonManagerEdit>
  );
};
