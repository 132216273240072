import ProCard from '@ant-design/pro-card';

export const BackendDashboardView = () => {
  return (
    <ProCard split="horizontal">
      <ProCard split="vertical">
        <ProCard title="目前持有的點數卡" colSpan={8}></ProCard>
        <ProCard title="最近執行記錄"></ProCard>
      </ProCard>
      <ProCard title="最近7日的執行次數"></ProCard>
    </ProCard>
  );
};
