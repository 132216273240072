import ProCard from '@ant-design/pro-card';
import {
  ProFormCheckbox,
  ProFormGroup,
  ProFormSwitch,
  ProFormText,
} from '@ant-design/pro-form';
import {BackendJkUserService, FrontendJkUserService} from '@common/api';
import {Alert, Space} from 'antd';
import {CommonTimeField} from 'components/Common/Form/TimeInput';
import {
  CommonManagerEdit,
  CommonManagerEditProps,
} from 'components/Common/Manager/Edit';
import {useAuthorize} from 'core/Authorize';
import {useApi} from 'hooks/useApi';

import {minute2time, time2minute} from 'libs/time';
import fp from 'lodash/fp';
import React from 'react';

export type JKUserEditProps = CommonManagerEditProps & {
  userId?: string;
};

export const JKUserEdit = ({
  userId,
  ...props
}: React.PropsWithChildren<JKUserEditProps>) => {
  const find = useApi(FrontendJkUserService.findOne);
  const add = useApi(
    userId ? BackendJkUserService.add : FrontendJkUserService.add
  );
  const update = useApi(FrontendJkUserService.update);
  const verifyUrl = useApi(FrontendJkUserService.verifyUrl);
  const {user} = useAuthorize<any>();

  const isTrial = user.role === 'TRIAL_USER';
  const isEdit = !!props.data;

  return (
    <CommonManagerEdit
      request={async () => {
        const data = props.data || {
          startTime: 0,
          endTime: 1439,
        };
        return fp.pipe(
          fp.update('startTime', minute2time),
          fp.update('endTime', minute2time)
        )({
          userId,
          weeks: ['日', '一', '二', '三', '四', '五', '六'],
          ...data,
        });
      }}
      onFinish={async values => {
        console.log(values);
        const requestBody = fp.pipe(
          fp.update('startTime', time2minute),
          fp.update('endTime', time2minute)
        )(values);
        if (props.data) {
          //edit
          await update({
            id: props.data.id,
            requestBody,
          });
        } else {
          //add
          await add({
            requestBody,
          });
        }
      }}
      {...props}
    >
      <Space style={{width: '100%'}} direction="vertical">
        <ProCard title="捷克論譠設定" bordered headerBordered type="inner">
          {isTrial && (
            <Alert
              message={
                isEdit
                  ? '試用會員無法修改捷克會員資料'
                  : '目前的等級為試用會員，當新增成功將無法進行修改，並且無法重覆新增已存在系統內的捷克會員'
              }
              type="warning"
            />
          )}
          <ProFormText name="userId" hidden />
          <ProFormText
            label="暱稱"
            name="nickname"
            disabled={isEdit && isTrial}
            rules={[{required: true}]}
          />
          <ProFormText
            label="帳號"
            name="username"
            disabled={isEdit && isTrial}
            rules={[{required: true}]}
          />
          <ProFormText.Password
            label="密碼"
            name="password"
            rules={[{required: true}]}
          />
          <ProFormText
            label="文章連結"
            name="postUrl"
            disabled={isEdit && isTrial}
            rules={[{required: true, type: 'url'}]}
          />
        </ProCard>
        <ProCard title="排程設定" bordered headerBordered type="inner">
          <ProFormSwitch
            name="isDisabled"
            label="暫停執行"
            checkedChildren="是"
            unCheckedChildren="否"
          />
          <ProFormGroup>
            <CommonTimeField
              label="開始時間"
              name="startTime"
              initialValue={'00:00'}
            />
            <CommonTimeField
              label="結束時間"
              name="endTime"
              initialValue={'23:59'}
            />
          </ProFormGroup>
          <ProFormCheckbox.Group
            label="星期"
            name="weeks"
            options={['日', '一', '二', '三', '四', '五', '六']}
            initialValue={['日', '一', '二', '三', '四', '五', '六']}
          />
        </ProCard>
      </Space>
    </CommonManagerEdit>
  );
};
