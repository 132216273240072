import {PageLoading} from '@ant-design/pro-layout';
import {Result} from 'antd';
import React, {useCallback, useEffect, useState} from 'react';

let errorHandle = (err: any) => {
  try {
    return err.message.toString();
  } catch (err) {
    return 'Error';
  }
};

export const setErrorHandle = (handler: (err: any) => any) => {
  errorHandle = handler;
};

export function useAsyncCallback<T = any>(
  fn: (...props: any) => Promise<T>,
  deps: any[]
) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<any | null>(null);

  const execute = useCallback(
    async (...props: any) => {
      let result: T;
      setLoading(true);
      try {
        result = await fn(...props);
        setLoading(false);
        return result;
      } catch (err: any) {
        console.log('async callback', {err});
        setLoading(false);
        setError(errorHandle(err));
        throw err;
      }
    },
    [...deps]
  );
  return {
    execute,
    loading,
    error,
  };
}

type AsyncMemoResult<T> = {
  error: string;
  result?: T;
  execute: () => Promise<any>;
  loading: boolean;
  setResult: React.Dispatch<React.SetStateAction<T | undefined>>;
  isReady: boolean;
};
export function useAsyncMemo<T>(
  fn: () => Promise<T>,
  deps: any[]
): AsyncMemoResult<T> {
  const [isReady, setIsReady] = useState(false);
  const {execute, loading, error} = useAsyncCallback(fn, deps);
  const [result, setResult] = useState<T>();

  const $execute = useCallback(async () => {
    try {
      const result = await execute();
      setResult(result);
    } catch (err) {
      setResult(undefined as any);
    }
    setIsReady(true);
  }, [execute]);
  useEffect(() => {
    $execute();
  }, [$execute]);

  return {
    error,
    loading: loading,
    execute: $execute,
    result,
    setResult,
    isReady,
  };
}

export const renderLoadingAndError = ({
  loading,
  error,
  result,
}: AsyncMemoResult<any>) => {
  if (loading || result === undefined) {
    return <PageLoading />;
  }
  if (error) {
    return <Result status={'500'} title={error} />;
  }
  return false;
};

type AsyncMemoLoadingProps<T extends AsyncMemoResult<any>> = {
  children: (props: Required<T>) => React.ReactNode;
} & T;
export function AsyncMemoLoading<T extends AsyncMemoResult<any>>({
  children,
  ...props
}: AsyncMemoLoadingProps<T>) {
  const {loading, result, error} = props;

  if (error) {
    return <Result status={'500'} title={error} />;
  }
  if (loading || result === undefined) {
    return <PageLoading />;
  }

  return <>{children(props as any)}</>;
}
