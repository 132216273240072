import {BackendUserOffDatePlanService} from '@common/api';
import {CommonManager} from 'components/Common/Manager';
import {useApi} from 'hooks/useApi';
import {genRequest} from 'libs/genRequest';

export type BackendUserOffDatePlanManagerProps = {
  userId: number;
};
export const BackendUserOffDatePlanManager: React.FC<BackendUserOffDatePlanManagerProps> =
  ({userId}) => {
    const find = useApi(BackendUserOffDatePlanService.findAll);

    return (
      <CommonManager
        request={genRequest(find)}
        params={{userId}}
        columns={[
          {
            title: '訂單狀態',
            dataIndex: 'status',
            valueEnum: {
              CREATE: '等待付款',
              RECEIVE1: '等待付款',
              RECEIVE2: '付款成功',
            },
          },
          {
            title: '購買內容',
            dataIndex: 'orderItem',
          },
          {
            title: '到期日',
            dataIndex: 'expired',
            valueType: 'date',
          },
          {title: '訂單編號', dataIndex: 'orderId'},
        ]}
      />
    );
  };
