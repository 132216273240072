import ProTable from '@ant-design/pro-table';
import {BackendJkUserService} from '@common/api';
import {CommonLink} from 'components/Common/Route/Link';
import {useApi} from 'hooks/useApi';
import {Link} from 'react-router-dom';

export const BackendStatsByPostURL = () => {
  const getRows = useApi(BackendJkUserService.statsByUrl);
  return (
    <ProTable
      search={false}
      rowKey="postUrl"
      columns={[
        {title: '當前使用數量', dataIndex: 'count'},
        {title: '帖子連結', dataIndex: 'postUrl'},
      ]}
      expandable={{
        expandedRowRender: row => {
          return (
            <ProTable
              dataSource={row.users}
              pagination={false}
              tableLayout="fixed"
              columns={[
                {
                  title: '會員帳號',
                  dataIndex: ['user', 'username'],
                  width: '100px',
                  render: (dom, row) => (
                    <CommonLink
                      to={`/${row.userId}`}
                      state={{title: `詳細-${dom}`}}
                    >
                      {dom}
                    </CommonLink>
                  ),
                },
                {
                  title: '會員名稱',
                  dataIndex: ['user', 'nickname'],
                  width: '100px',
                },
              ]}
              search={false}
              options={false}
            />
          );
        },
      }}
      request={async () => {
        const rows = await getRows();
        return rows;
      }}
    />
  );
};
