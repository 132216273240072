import ProDescriptions from '@ant-design/pro-descriptions';
import {ModalForm, ProFormDependency, ProFormText} from '@ant-design/pro-form';
import {FrontendMeService} from '@common/api';
import {Button} from 'antd';
import {CustomProForm} from 'components/Common/Form';
import {CustomModalForm} from 'components/Common/Form/ModalForm';
import {useAuthorize} from 'core/Authorize';
import {useApi} from 'hooks/useApi';
import {useTimeLock} from 'hooks/useTimeLock';
import React, {useCallback} from 'react';

export const BackendVerifyEmail = ({
  children,
}: React.PropsWithChildren<any>) => {
  const [time, setTime] = useTimeLock();
  const {onFetch, user} = useAuthorize<any>();
  const verify = useApi(FrontendMeService.verifyEmail, {
    successMessage: '驗證成功',
  });
  const sendMail = useApi(FrontendMeService.sendVerifyEmail, {
    successMessage: '重新發送驗證信成功',
  });
  const resendEmail = useCallback(async () => {
    sendMail();
    setTime(60);
  }, []);
  return (
    <CustomModalForm
      trigger={children}
      title="驗證Email"
      initialValues={user}
      onFinish={async values => {
        await verify({requestBody: values});
        await onFetch?.();
        return true;
      }}
    >
      <ProFormText name="email" readonly label="驗證信箱" />
      <ProFormText
        name="verifyCode"
        label="驗證碼"
        placeholder={'請輸入驗證碼進行驗證'}
        rules={[{required: true}]}
      />
      <Button
        type="link"
        size="small"
        onClick={resendEmail}
        disabled={time > 0}
      >
        沒有收到驗證信？點擊重新發送。
        {time > 0 ? `(等待${time}秒才能再次發送)` : null}
      </Button>
    </CustomModalForm>
  );
};
