import {CheckCard} from '@ant-design/pro-card';
import ProForm, {
  ProFormDigit,
  ProFormItemProps,
  ProFormSelect,
} from '@ant-design/pro-form';
import {
  BackendPlanService,
  FrontendPlanService,
  PublicPlanService,
} from '@common/api';
import {Typography} from 'antd';
import {CustomButton} from 'components/Common/Button';
import {CommonManagerEdit} from 'components/Common/Manager/Edit';
import {FrontendPlanCard} from 'components/Frontend/Home/Widget';
import {useApi} from 'hooks/useApi';
import {useAsyncMemo} from 'hooks/useAsync';
import {PropsWithChildren} from 'react';

export type BackendPlanSelectProps = ProFormItemProps & {
  isAdmin?: boolean;
  onChangePlan?: (data: any) => void;
};

export const BackendPlanSelect = (
  props: PropsWithChildren<BackendPlanSelectProps>
) => {
  const findPlan = useApi(
    props.isAdmin ? BackendPlanService.getActiveList : PublicPlanService.getList
  );
  const planList = useAsyncMemo(async () => {
    const res = await findPlan();
    return res.data;
  }, []);
  if (!planList.result) {
    return null;
  }
  if (planList.result.length === 0) {
    return (
      <Typography.Text type="danger">目前沒有可購買的方案</Typography.Text>
    );
  }
  return (
    <ProForm.Item
      label="選擇儲值方案"
      name="planId"
      initialValue={planList.result?.[0]?.id || ''}
      {...props}
    >
      <CheckCard.Group
        onChange={value => {
          const item = planList.result?.find(x => x.id === value);
          if (item) {
            props.onChangePlan && props.onChangePlan(item);
          }
        }}
      >
        {/* 儲值<span>{data.price}</span>元可得<span>{data.point}</span>點
          {data.free ? `,再送${data.free}點` : ''}
          ,執行一次扣<span>{data.fee}</span>點 */}
        {planList.result.map(x => (
          <CheckCard
            title={x.title}
            description={`儲值${x.price}元可得${x.point}點${
              x.free ? `,再送${x.free}點` : ''
            },執行一次扣${x.fee}點`}
            value={x.id as number}
            size="small"
          />
        ))}
      </CheckCard.Group>
    </ProForm.Item>
  );
};
