import {BackendUserPointCardService} from '@common/api';
import {Modal} from 'antd';
import {CustomButton} from 'components/Common/Button';
import {useApi} from 'hooks/useApi';
import {useCallback} from 'react';

export const BackendOrderAccept = ({data, action}: any) => {
  const update = useApi(BackendUserPointCardService.update);
  const onClick = useCallback(() => {
    Modal.confirm({
      content: '是否要無視金流系統直接強制允許此訂單？',
      onOk: async () => {
        await update({
          id: data.id,
          requestBody: {
            status: 'RECEIVE2',
          },
        });
        action.reload();
      },
    });
  }, [data]);

  return (
    <CustomButton color="green" onClick={onClick}>
      通過訂單
    </CustomButton>
  );
};

export const BackendOrderCancel = ({data, action}: any) => {
  const update = useApi(BackendUserPointCardService.update);
  const onClick = useCallback(() => {
    Modal.confirm({
      content: '是否要無視金流系統直接強制取消此訂單？',
      onOk: async () => {
        await update({
          id: data.id,
          requestBody: {
            status: 'CANCEL',
          },
        });
        action.reload();
      },
    });
  }, [data]);
  return (
    <CustomButton color="red" onClick={onClick}>
      取消訂單
    </CustomButton>
  );
};

export const BackendOrderDelete = ({data, action}: any) => {
  const del = useApi(BackendUserPointCardService.delete);
  const onClick = useCallback(() => {
    Modal.confirm({
      content: '是否要刪除該訂單？',
      onOk: async () => {
        await del({
          id: data.id,
        });
        action.reload();
      },
    });
  }, [data]);
  return (
    <CustomButton color="red" onClick={onClick}>
      刪除訂單
    </CustomButton>
  );
};
