import { request as __request } from '../core/request';
export class FrontendSupportService {
    /**
     * [BASE] Search From SupportEntity
     * @returns any Success
     * @throws ApiError
     */
    static findAll({ where, page, }) {
        return __request({
            method: 'GET',
            path: `/api/frontend/supports`,
            query: {
                'where': where,
                'page': page,
            },
        });
    }
    /**
     * [BASE] Add To SupportEntity
     * @returns any Success
     * @throws ApiError
     */
    static add({ requestBody, }) {
        return __request({
            method: 'POST',
            path: `/api/frontend/supports`,
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * [BASE] Batch Update To SupportEntity
     * @returns void
     * @throws ApiError
     */
    static batchUpdate({ requestBody, }) {
        return __request({
            method: 'PUT',
            path: `/api/frontend/supports`,
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * [BASE] FindOne From SupportEntity
     * @returns any Success
     * @throws ApiError
     */
    static findOne({ id, }) {
        return __request({
            method: 'GET',
            path: `/api/frontend/supports/${id}`,
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * [BASE] Update To SupportEntity
     * @returns any Success
     * @throws ApiError
     */
    static update({ id, requestBody, }) {
        return __request({
            method: 'PUT',
            path: `/api/frontend/supports/${id}`,
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * [BASE] Delete From SupportEntity
     * @returns void
     * @throws ApiError
     */
    static delete({ id, softDelete, }) {
        return __request({
            method: 'DELETE',
            path: `/api/frontend/supports/${id}`,
            query: {
                'softDelete': softDelete,
            },
            errors: {
                400: `Bad Request`,
            },
        });
    }
}
