import {
  BackendJkUserFreeLogService,
  FrontendJkUserFreeLogService,
} from '@common/api';
import {VerifyEmail} from 'components/Backend/VerifyEmail';
import {CommonManager} from 'components/Common/Manager';
import {CommonTable} from 'components/Common/Table';
import {useAuthorize} from 'core/Authorize';
import {useApi} from 'hooks/useApi';
import {genRequest} from 'libs/genRequest';
import React from 'react';
import {JKUserFreeLogTimeline} from './Timeline';

export type JKUserFreeLogManagerProps = {
  userId?: string;
  jkUserId?: string;
  cardId?: string;
};

export const JKUserFreeLogManager = ({
  userId,
  jkUserId,
  cardId,
}: React.PropsWithChildren<JKUserFreeLogManagerProps>) => {
  const {user} = useAuthorize<any>();
  const findAll = useApi(
    userId
      ? BackendJkUserFreeLogService.findAll
      : FrontendJkUserFreeLogService.findAll
  );
  return (
    <VerifyEmail ignore={!!userId}>
      <CommonManager
        rowActions={[JKUserFreeLogTimeline]}
        request={genRequest(findAll)}
        params={{
          userId,
          jkUserId,
          cardId,
          sortKey: 'createdAt',
          sortType: 'DESC',
        }}
        search={{}}
        columns={[
          CommonTable.Utils.Sorter({
            title: '暱稱',
            dataIndex: ['jkUser', 'nickname'],
            hideInSearch: true,
          }),
          CommonTable.Utils.Sorter({
            title: '執行帳號',
            dataIndex: ['jkUser', 'username'],
            hideInSearch: true,
          }),
          CommonTable.Utils.Sorter({
            title: '使用點數',
            dataIndex: ['card', 'plan', 'fee'],
            hideInSearch: true,
          }),
          CommonTable.Utils.Sorter({
            title: '執行平台',
            dataIndex: ['platform'],
            hideInTable: user?.role !== 'ADMIN',
            hideInSearch: true,
          }),
          CommonTable.Utils.Sorter({
            title: '執行狀態',
            dataIndex: ['status'],
            valueEnum: {
              CREATE: '執行中',
              SUCCESS: '執行成功',
              FAILED: '執行失敗',
              TIME_OUT: '執行逾期',
              STOP: '強制中止',
            },
            hideInSearch: false,
          }),
          CommonTable.Utils.Sorter({
            title: '執行時間',
            dataIndex: 'createdAt',
            valueType: 'dateTime',
            hideInSearch: true,
          }),
        ]}
      />
    </VerifyEmail>
  );
};
