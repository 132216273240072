import {BackendUserService} from '@common/api';
import {CommonManager} from 'components/Common/Manager';
import {CommonTable} from 'components/Common/Table';
import {useApi} from 'hooks/useApi';
import {genRequest} from 'libs/genRequest';
import withProps from 'recompose/withProps';
import {BatchDelete} from './BatchDelete';
import {BackendUserEdit} from './Edit';

const DetailLink = withProps((props: any) => ({
  children: '檢視',
  state: {
    title: `${props.data.username}-詳細資料`,
  },
}))(CommonManager.RowButton);

const EditLink = withProps({
  children: '編輯',
})(CommonManager.RowButton);

export const BackendUserManager = () => {
  const del = useApi(BackendUserService.delete);
  return (
    <CommonManager
      headerActions={[BackendUserEdit, BatchDelete]}
      rowActions={[DetailLink, BackendUserEdit]}
      request={genRequest(BackendUserService.findAll)}
      onDelete={async row => {
        await del({id: row.id});
      }}
      rowSelection={{}}
      columns={[
        CommonTable.Utils.Sorter({
          title: '帳號',
          dataIndex: 'username',
        }),
        CommonTable.Utils.Sorter({
          title: '名稱',
          dataIndex: 'nickname',
        }),
        CommonTable.Utils.Sorter({
          title: '電話',
          dataIndex: 'phone',
        }),
        CommonTable.Utils.Sorter({
          title: '權限',
          dataIndex: 'role',
          valueEnum: {
            USER: '一般使用者',
            ADMIN: '管理員',
            TRIAL_USER: '試用會員',
          },
        }),
        CommonTable.Utils.Sorter({
          title: 'VIP休假到期日',
          dataIndex: '$offDateExpired',
          valueType: 'date',
        }),
        CommonTable.Utils.Sorter({
          title: '置頂點數剩餘',
          dataIndex: '$currentPoint',
          valueType: 'digit',
        }),
        CommonTable.Utils.Sorter({
          title: '帖子數量',
          dataIndex: '$jkUserCount',
        }),
        CommonTable.Utils.Sorter({
          title: '最近執行時間',
          dataIndex: '$lastRunAt',
          valueType: 'dateTime',
        }),
        CommonTable.Utils.Sorter({
          title: '建立時間',
          dataIndex: 'createdAt',
          valueType: 'dateTime',
        }),
        CommonTable.Utils.Sorter({
          title: '最後更新時間',
          dataIndex: 'updatedAt',
          valueType: 'dateTime',
        }),
      ]}
    />
  );
};
