import {Card} from 'antd';

const AboutIndex: React.FC = () => {
  return (
    <div>
      <Card>關於我…</Card>
    </div>
  );
};

export const title = '關於我';

export const orderNum = 1;

export default AboutIndex;
