import ProCard from '@ant-design/pro-card';
import {
  ProFormCheckbox,
  ProFormDatePicker,
  ProFormDependency,
  ProFormDigit,
  ProFormGroup,
  ProFormRadio,
  ProFormSelect,
  ProFormSwitch,
  ProFormText,
} from '@ant-design/pro-form';
import {BackendPlanService} from '@common/api';
import {CustomButton} from 'components/Common/Button';
import {
  CommonManagerEdit,
  CommonManagerEditProps,
} from 'components/Common/Manager/Edit';
import {useApi} from 'hooks/useApi';
import fp from 'lodash/fp';
import moment from 'moment';
import React, {useMemo} from 'react';

export const BackendPlanEdit = (props: CommonManagerEditProps) => {
  const add = useApi(BackendPlanService.add);
  const edit = useApi(BackendPlanService.update);
  const isEffect = useMemo(() => {
    if (props.data) {
      return props.data.effectAt && new Date(props.data.effectAt) < new Date();
    }
    return false;
  }, [props.data]);

  const title = useMemo(() => {
    if (props.data) {
      return isEffect ? '建立新版本' : '編輯';
    }
    return '新增';
  }, [props.data, isEffect]);

  const trigger = useMemo(() => {
    if (props.data) {
      return isEffect ? (
        <CustomButton.Edit>建立新版本</CustomButton.Edit>
      ) : (
        <CustomButton.Edit>編輯</CustomButton.Edit>
      );
    } else {
      return <CustomButton.Add>新增</CustomButton.Add>;
    }
  }, [props.data, isEffect]);

  return (
    <CommonManagerEdit
      title={title}
      onFinish={async values => {
        const requestBody = Object.assign(
          {
            price: 0,
            free: 0,
          },
          values
        );
        if (props.data) {
          if (isEffect) {
            await add({
              requestBody: {
                ...props.data,
                ...requestBody,
                version: (props.data.version || 0) + 1,
              },
            });
          } else {
            await edit({id: props.data.id, requestBody: requestBody});
          }
        } else {
          await add({requestBody: values});
        }
        return true;
      }}
      trigger={trigger}
      request={async () => {
        let data = props.data;
        if (data) {
          data = fp.pipe(fp.set('effectAt', undefined))(data);
        }

        return (
          data || {
            fee: 1,
            effectAt: moment(),
            isEnabled: true,
          }
        );
      }}
      {...props}
    >
      <ProFormSelect
        label="方案類型"
        name="type"
        initialValue={'SHOP_ITEM'}
        options={[
          {
            label: '商品',
            value: 'SHOP_ITEM',
          },
          {
            label: '註冊贈送',
            value: 'GIFT',
          },
          {
            label: '後台專用',
            value: 'ADMIN',
          },
        ]}
        readonly={!!props.data}
        rules={[{required: true}]}
      />
      <ProFormText label="方案名稱" name="title" rules={[{required: true}]} />
      <ProFormDigit
        label="點數總額"
        name="point"
        max={10000000}
        min={0}
        rules={[{required: true}, {min: 0, max: 1000000, type: 'number'}]}
      />
      <ProFormDependency name={['type']}>
        {({type}) =>
          type === 'SHOP_ITEM' ? (
            <ProFormDigit
              label="額外贈送點數"
              name="free"
              rules={[{required: true}, {min: 0, max: 1000000, type: 'number'}]}
              max={10000000}
              min={0}
            />
          ) : null
        }
      </ProFormDependency>
      <ProFormDigit
        label="每次執行扣除點數"
        name="fee"
        rules={[{required: true}]}
        readonly
      />
      <ProFormDependency name={['type']}>
        {({type}) =>
          type === 'SHOP_ITEM' ? (
            <ProFormDigit
              label="價格(NTD)"
              name="price"
              rules={[{required: true}, {min: 1, max: 1000000, type: 'number'}]}
            />
          ) : null
        }
      </ProFormDependency>

      <ProFormDatePicker
        label="生效時間"
        name="effectAt"
        rules={[{required: true}]}
      />
      <ProFormSwitch label="啟用" name="isEnabled" rules={[{required: true}]} />
    </CommonManagerEdit>
  );
};
