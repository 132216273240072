import {BackendPlanService, BackendOffDatePlanService} from '@common/api';
import {Modal} from 'antd';
import {CommonManager} from 'components/Common/Manager';
import {CommonTable} from 'components/Common/Table';
import {useApi} from 'hooks/useApi';
import {genRequest} from 'libs/genRequest';
import {BackendPlanEdit} from '../Plan/Edit';
import {BackendOffDatePlanEdit} from './Edit';

export const BackendOffDatePlanManager = () => {
  const findAll = useApi(BackendOffDatePlanService.findAll);
  const del = useApi(BackendOffDatePlanService.delete);
  const edit = useApi(BackendOffDatePlanService.update);
  return (
    <CommonManager
      headerActions={[BackendOffDatePlanEdit]}
      params={{
        sortKey: 'title',
        sortType: 'ASC',
      }}
      rowActions={(row, act) => {
        return [BackendOffDatePlanEdit];
      }}
      onDelete={async row => {
        await del(row);
      }}
      request={genRequest(findAll)}
      columns={[
        CommonTable.Utils.Sorter({
          title: '方案名稱',
          dataIndex: 'title',
        }),
        CommonTable.Utils.Sorter({
          title: '有效天數',
          dataIndex: 'day',
          valueType: 'digit',
        }),
        CommonTable.Utils.Sorter({
          title: '價格',
          dataIndex: 'price',
          valueType: 'digit',
        }),
      ]}
    />
  );
};
