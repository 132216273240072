import React, {useMemo} from 'react';
import ProLayout, {BasicLayoutProps} from '@ant-design/pro-layout';
import {PageProps, PageTemplateProps} from 'core/DynamicPages';
import {createPages} from 'core/DynamicPages/createRoutes';
import {fp} from 'libs/utils/fp';
import {Route} from '@ant-design/pro-layout/lib/typings';
import _ from 'lodash';
import {Link, useRouteMatch} from 'react-router-dom';

const TAG = '[Common/Layout]';

type BaseLayoutProps = BasicLayoutProps &
  PageTemplateProps & {
    getTitle?: (page: PageProps) => string;
    filterPage?: (page: PageProps) => boolean;
  };

const noopGetTitle: any = fp.get('title');
const noopGetMenuPath: any = fp.get('groups');

export const BaseLayout = ({
  page,
  children,
  getTitle = noopGetTitle,
  filterPage,
  ...layoutProps
}: BaseLayoutProps) => {
  const match = useRouteMatch();
  /** 權限過濾 */
  const processedPage = useMemo(() => {
    return fp.pipe(
      fp.update(
        'pages',
        fp.pipe(filterPage ? fp.pickBy(filterPage) : fp.identity)
      )
    )(page);
  }, [page, filterPage]);

  const routes = useMemo(() => {
    const routes: Route[] = [];
    const routesByPath: {[key: string]: Route} = {};
    const childrenPageCount = fp.pipe(
      fp.pickBy((x: any) => x.title),
      fp.keys,
      fp.getOr(0, 'length')
    );
    const getRoutes = (pages: any, parentItems: any[]) => {
      const keys = fp.pipe(
        fp.toPairs,
        fp.sortBy(fp.get(['1', 'orderNum'])),
        fp.map(fp.get(0))
      )(pages);
      for (const key of keys) {
        if (!key) {
          continue;
        }
        const item = pages[key];
        const title = getTitle(item);

        console.log(TAG, {
          key,
          title,
          item,
          pages: item.page,
          pageCount: childrenPageCount(item.pages),
          parentItems,
        });

        if (item.pages && childrenPageCount(item.pages) > 1) {
          const obj: Route = {
            name: item.title,
            routes: [],
            path: item.path,
            icon: item.icon,
          };
          if (!routesByPath[item.path]) {
            //如果沒有資料就建立一個新的obj
            routesByPath[item.path] = obj;
          }
          const lastParentItem = parentItems[parentItems.length - 1];
          if (lastParentItem && routesByPath[lastParentItem.path]) {
            routesByPath[lastParentItem.path]?.routes?.push(obj);
          } else {
            routes.push(obj);
          }
          getRoutes(item.pages, [...parentItems, item]);
        } else {
          if (parentItems.length > 0) {
            const lastParentItem = fp.last(parentItems);
            if (lastParentItem && lastParentItem.title !== title) {
              console.log(
                TAG,
                'ADD Routes',
                routesByPath?.[lastParentItem.path]
              );
              routesByPath?.[lastParentItem.path]?.routes?.push({
                name: title,
                routes: [],
                path: item.path,
                icon: item.icon,
              });
            }
          } else {
            routes.push({
              name: title,
              routes: [],
              path: item.path,
              icon: item.icon,
            });
          }
        }

        console.log(TAG, {routes, routesByPath});

        // if (parentItems.length > 0) {
        //   let menuTitle;
        //   let targetPath;
        //   for (const parentItem of parentItems) {
        //     menuTitle = parentItem.title;
        //     targetPath = parentItem.path;
        //     const obj: Route = {
        //       name: menuTitle,
        //       routes: [],
        //       path: parentItem.path,
        //       icon: parentItem.icon,
        //     };
        //     const findIndexItem = routesByPath[parentItem.path];
        //     if (findIndexItem) {
        //       if (findIndexItem.name !== menuTitle) {
        //         findIndexItem.routes?.push(obj);
        //       }
        //     } else {
        //       routes.push(obj);
        //       routesByPath[parentItem.path] = obj;
        //     }
        //   }

        //   if (menuTitle !== item.title) {
        //     routesByPath[targetPath].routes?.push({
        //       path: item.path,
        //       name: title,
        //       routes: [],
        //       icon: item.icon,
        //     });
        //   }
        //   if (item.pages && childrenPageCount(item.pages) > 1) {
        //     getRoutes(item.pages, [...parentItems, item]);
        //   }
        // } else {
        //   if (item.pages && childrenPageCount(item.pages) > 1) {
        //     getRoutes(item.pages, [item]);
        //   } else {
        //     routes.push({
        //       name: title,
        //       routes: [],
        //       path: item.path,
        //       icon: item.icon,
        //     });
        //   }
        // }
      }
    };

    getRoutes(processedPage.pages, []);

    if (routes.length === 1) {
      routes[0].flatMenu = true;
    }

    return routes;
  }, [processedPage, getTitle]);

  return (
    <ProLayout
      route={{
        routes: routes,
        path: '/',
      }}
      fixSiderbar
      fixedHeader
      menuItemRender={(item, dom, props) => {
        return <Link to={`${item.path}`}>{dom}</Link>;
      }}
      style={{margin: 0}}
      // contentStyle={{margin: 0}}
      {...layoutProps}
    >
      {createPages(processedPage.pages, [match.url], processedPage)}
    </ProLayout>
  );
};
