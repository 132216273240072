import {Button} from 'antd';
import styled from 'styled-components';

export const FrontendButton = styled(Button).attrs({size: 'large'})`
  color: #fff;
  border: 1px solid #fff;
  background-color: rgba(0, 0, 0, 0.2);
  padding: 12px;
  border-radius: 24px;
  height: unset;
`;
