import {PageContainer} from '@ant-design/pro-layout';
import {Card, Col, Row, Tag} from 'antd';
import _ from 'lodash';
import {useState} from 'react';
import {Link} from 'react-router-dom';

const ProductIndex: React.FC = () => {
  const [state, setState] = useState(_.times(20));
  return (
    <PageContainer>
      <Row gutter={[32, 32]}>
        {state.map(x => (
          <Col>
            <Link to={`/product/${x}`}>
              <Card
                style={{width: 300, maxWidth: '100%'}}
                key={x}
                cover={
                  <img src="http://via.placeholder.com/200?text=product" />
                }
                actions={[]}
                hoverable
              >
                <Row>
                  <Col flex="auto">Product {x}</Col>
                  <Col>
                    <div>$ 2,000</div>
                  </Col>
                </Row>
              </Card>
            </Link>
          </Col>
        ))}
      </Row>
    </PageContainer>
  );
};

export const title = '商品列表';

export default ProductIndex;
