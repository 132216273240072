import React, {useCallback, useState} from 'react';
import {CoreDynamicPages} from './core/DynamicPages';
import {CoreAuthorize} from './core/Authorize';
import 'antd/dist/antd.less';
import 'antd/lib/style/themes/default.less';
import '@ant-design/pro-table/dist/table.css';
import {Login} from './components/Login';
import styled, {ThemeProvider} from 'styled-components';
import {defaultTheme} from './themes/default';
import {BrowserRouter, Route, Switch} from 'react-router-dom';
import {ConfigProvider} from 'antd';
import zhTW from 'antd/lib/locale/zh_TW';
import {useApi} from 'hooks/useApi';
import {AuthService, UserEntityProfileAuth} from '@common/api';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import moment from 'moment';
import 'moment/locale/zh-tw';
moment.locale('zh-tw');

function App() {
  const getProfile = useApi(AuthService.getProfile, {disabledNotify: true});
  const [user, setUser] = useState<UserEntityProfileAuth>();
  const $getProfile = useCallback(async () => {
    try {
      const res = await getProfile();
      setUser(res.data);
    } catch (err) {
      setUser(undefined);
    }
  }, []);
  return (
    <CoreAuthorize onFetch={$getProfile} user={user}>
      <ConfigProvider locale={zhTW}>
        <BrowserRouter>
          <ThemeProvider theme={defaultTheme}>
            <Switch>
              <Route
                path="/admin"
                children={
                  <CoreDynamicPages
                    context={require.context('./admin', true, /tsx/)}
                    baseURL="/admin"
                  />
                }
              ></Route>
              <Route
                path="/"
                children={
                  <CoreDynamicPages
                    context={require.context('./pages', true, /tsx/)}
                  />
                }
              ></Route>
            </Switch>
          </ThemeProvider>
        </BrowserRouter>
      </ConfigProvider>
    </CoreAuthorize>
  );
}

export default App;
