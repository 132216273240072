import { request as __request } from '../core/request';
export class FrontendMeService {
    /**
     * 取得目前可以執行的次數
     * @returns any Success
     * @throws ApiError
     */
    static quota() {
        return __request({
            method: 'GET',
            path: `/api/frontend/me/quota`,
        });
    }
    /**
     * Edit Profile
     * @returns any Success
     * @throws ApiError
     */
    static editProfile({ requestBody, }) {
        return __request({
            method: 'POST',
            path: `/api/frontend/me/profile`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Edit Password
     * @returns CommonResponse Success
     * @throws ApiError
     */
    static setPassword({ requestBody, }) {
        return __request({
            method: 'POST',
            path: `/api/frontend/me/password`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * 取得目前休假方案的到期日, 如果為null表示尚未有購買記錄，字串為日期，空值為不限制
     * @returns any Success
     * @throws ApiError
     */
    static getMeOffDatePlan() {
        return __request({
            method: 'GET',
            path: `/api/frontend/me/off_date_plan`,
        });
    }
    /**
     * 發送驗證信
     * @returns any Success
     * @throws ApiError
     */
    static sendVerifyEmail() {
        return __request({
            method: 'POST',
            path: `/api/frontend/me/send_verify_email`,
        });
    }
    /**
     * 驗證碼輸入
     * @returns any Success
     * @throws ApiError
     */
    static verifyEmail({ requestBody, }) {
        return __request({
            method: 'POST',
            path: `/api/frontend/me/verify_email`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }
}
