import {OrderedListOutlined} from '@ant-design/icons';
import {Space} from 'antd';
import {BackendSupportManager} from 'components/Backend/Support/Manager';
import {WatchUnread} from 'components/Backend/Support/Unread';

export const title = (
  <Space>
    問題回報管理
    <WatchUnread />
  </Space>
);
export const orderNum = 99;

export const icon = <OrderedListOutlined />;
export default BackendSupportManager;
