import React, {useMemo} from 'react';
import {Route, Switch} from 'react-router-dom';
import {PageProps, PagesObj, PageTemplateProps} from '.';
import {isPage} from './utils';
import _ from 'lodash';
import {CoreDynamicPagesContext} from './Context';

const getRoutePath = (strArr: string[]) => {
  const path = strArr
    .join('/')
    .replace('/index', '')
    .replace(/\{(\w*)\}/g, ':$1');
  return path === '' ? '/' : path;
};

const getSortedKey = (obj: any) => {
  if (obj) {
    return _.sortBy(Object.keys(obj), key => {
      if (key === 'index') {
        return 3;
      } else if (key.includes('{')) {
        return 2;
      } else {
        return 1;
      }
    });
  }
  return [];
};

export const createPages = (
  pages: {[key: string]: PageProps},
  parent: string[],
  parentPage: PageProps
) => {
  return (
    <Switch>
      {getSortedKey(pages).map(key => {
        const x = pages[key];
        const paths = [...parent, key];
        return (
          <Route
            path={getRoutePath(paths)}
            key={key}
            render={() => createTemp(x, paths, parentPage)}
            exact={key !== 'index' && !x.pages}
            // strict={!x.pages}
          />
        );
      })}
    </Switch>
  );
};

export const createTemp = (
  page: PageProps,
  parent: string[] = [''],
  parentPage?: PageProps
) => {
  let Temp: any = React.Fragment;
  if (page.component) {
    Temp = page.component;
  }

  return (
    <CoreDynamicPagesContext.Provider value={{page, parentPage}}>
      <Temp page={page}>
        {page.pages && createPages(page.pages, parent, page)}
      </Temp>
    </CoreDynamicPagesContext.Provider>
  );
};
