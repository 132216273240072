import {Editor, IAllProps} from '@tinymce/tinymce-react';

const init = {
  skin: 'oxide',
  contextmenu: '',
  menubar: false,
  plugins:
    'preview powerpaste searchreplace autolink directionality advcode visualblocks visualchars fullscreen image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount tinymcespellchecker a11ychecker imagetools textpattern help',
  toolbar:
    '|fontsizeselect bold italic strikethrough forecolor backcolor | link image | alignleft aligncenter alignright alignjustify  | numlist bullist outdent indent ',
  mobile: {
    theme: 'silver',
  },
  images_upload_url: '/api/upload',
  automatic_uploads: true,
  language: 'zh_TW',
  height: '100%',
  minHeight: 500,
  auto_focus: true,
  relative_urls: false,
  convert_urls: true,
  remove_script_host: true,
};
export const CommonFormDraft = ({value, onChange, ...props}: any) => {
  return (
    <div style={{height: 500}}>
      <Editor
        init={init as any}
        value={value || ''}
        onEditorChange={e => {
          onChange(e);
        }}
      />
    </div>
  );
};
