import { request as __request } from '../core/request';
export class BackendPlanService {
    /**
     * 取得歷史更新資料
     * @returns any Success
     * @throws ApiError
     */
    static getHistory({ id, }) {
        return __request({
            method: 'GET',
            path: `/api/backend/plan/${id}/history`,
        });
    }
    /**
     * @returns any Success
     * @throws ApiError
     */
    static getActiveList() {
        return __request({
            method: 'GET',
            path: `/api/backend/plan/active`,
        });
    }
    /**
     * [BASE] Search From PlanEntity
     * @returns any Success
     * @throws ApiError
     */
    static findAll({ where, page, }) {
        return __request({
            method: 'GET',
            path: `/api/backend/plan`,
            query: {
                'where': where,
                'page': page,
            },
        });
    }
    /**
     * [BASE] Add To PlanEntity
     * @returns any Success
     * @throws ApiError
     */
    static add({ requestBody, }) {
        return __request({
            method: 'POST',
            path: `/api/backend/plan`,
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * [BASE] Batch Update To PlanEntity
     * @returns void
     * @throws ApiError
     */
    static batchUpdate({ requestBody, }) {
        return __request({
            method: 'PUT',
            path: `/api/backend/plan`,
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * [BASE] FindOne From PlanEntity
     * @returns any Success
     * @throws ApiError
     */
    static findOne({ id, }) {
        return __request({
            method: 'GET',
            path: `/api/backend/plan/${id}`,
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * [BASE] Update To PlanEntity
     * @returns any Success
     * @throws ApiError
     */
    static update({ id, requestBody, }) {
        return __request({
            method: 'PUT',
            path: `/api/backend/plan/${id}`,
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * [BASE] Delete From PlanEntity
     * @returns void
     * @throws ApiError
     */
    static delete({ id, softDelete, }) {
        return __request({
            method: 'DELETE',
            path: `/api/backend/plan/${id}`,
            query: {
                'softDelete': softDelete,
            },
            errors: {
                400: `Bad Request`,
            },
        });
    }
}
