import {
  PageContainer,
  PageContainerProps,
  ProBreadcrumb,
} from '@ant-design/pro-layout';
import {Space, TabPaneProps} from 'antd';
import React, {useEffect, useContext} from 'react';
import {useState} from 'react';
import {useMemo} from 'react';
import {useHistory, useLocation} from 'react-router-dom';
import fp from 'lodash/fp';
import {useCallback} from 'react';
import {PageTemplateProps} from '../../../core/DynamicPages';
import {CommonCache, useCache} from '../../Common/Cache';
import {useResponsive} from '../../../hooks/useResponsive';
import {
  BackwardOutlined,
  LeftCircleFilled,
  LeftCircleOutlined,
  LeftOutlined,
} from '@ant-design/icons';

export const TabsTemplateContext = React.createContext({
  setTabTitle: (title: React.ReactNode, key?: string) => {},
  closeTab: (key?: string) => {},
});

export type TabsTemplateProps = Omit<PageContainerProps, 'TabList'> & {
  /** 預設的tabList */
  tabList?: PageContainerProps['tabList'];
  tabs?: {
    [key: string]: string;
  };
  RWD?: boolean;
} & Partial<PageTemplateProps>;

const noopObj = {};

/**
 * 後台 Tabs Page Container，Props 與 PageContainer 相同
 *
 * 當路由變更的時候，會去確認該路由是否存在於頁籤中，若不存在則新增一個新的頁籤。
 *
 * 新的頁籤標題預設都是 Loading..., 若要變更則需透過 `TabsTemplateContext` . `setTabTitle` 進行更新
 */
export const TabsTemplate = ({
  children,
  tabList,
  tabs = noopObj,
  page,
  title,
  RWD = true,
  ...pageProps
}: React.PropsWithChildren<TabsTemplateProps>) => {
  const {isTabletOrMobile} = useResponsive();
  const location = useLocation<{title?: string}>();
  const history = useHistory();
  const [cache, setCache] = useState({});
  const [pageTabs, setPageTabs] = useState<(TabPaneProps & {key?: string})[]>(
    () => []
  );
  const $tabList = useMemo(() => {
    const result: (TabPaneProps & {key?: string})[] = [];
    if (tabs && page) {
      for (const key in tabs) {
        result.push({
          key: page.pages[key].path,
          tab: tabs[key],
          closable: false,
        });
      }
    }
    return [...result, ...pageTabs];
  }, [page, tabs, pageTabs]);

  /** 監測 location 的變化，若pageTabs沒有對應的key, 則新增Tab */
  useEffect(() => {
    if (
      $tabList.length > 0 &&
      !$tabList.some(x => x.key === location.pathname)
    ) {
      console.log('tab useEffect');
      setPageTabs(items => [
        ...items,
        {key: location.pathname, tab: location?.state?.title || 'loading..'},
      ]);
    }
    if (isTabletOrMobile) {
      setTimeout(() => {
        const index = $tabList.findIndex(x => x.key === location.pathname);
        if (index === 0) {
          setPageTabs([]);
        }
      });
    }
  }, [location, $tabList, isTabletOrMobile]);

  const setTabTitle = useCallback(
    (title, key = location.pathname) => {
      console.log('setTableTitle');
      setTimeout(() => {
        setPageTabs(items =>
          items.map(x => {
            if (x.key === key) {
              return {
                ...x,
                tab: title,
              };
            }
            return x;
          })
        );
      });
    },
    [location]
  );

  const close = useCallback(
    (key: string = location.pathname) => {
      if ($tabList) {
        let nowIdx = $tabList.findIndex(x => x.key === key);
        if (nowIdx && nowIdx > 0) {
          nowIdx -= 1;
        }

        if ($tabList && $tabList[nowIdx] && $tabList[nowIdx].key) {
          if (location.pathname === key) {
            history.push($tabList[nowIdx].key as string);
          }
        }

        setPageTabs(fp.remove(fp.pathEq('key', key)));
        setCache(fp.omit([key]));
      }
    },
    [location.pathname, $tabList]
  );

  const $pageTitle = useMemo(() => {
    if (isTabletOrMobile && RWD && $tabList.length > 1) {
      return $tabList[1].tab;
    }
  }, [isTabletOrMobile, RWD, $tabList]);

  return (
    <CommonCache state={cache} setState={setCache}>
      <TabsTemplateContext.Provider
        value={{
          setTabTitle,
          closeTab: close,
        }}
      >
        <PageContainer
          tabList={isTabletOrMobile && RWD ? undefined : $tabList}
          fixedHeader
          title={
            $pageTitle ? (
              <Space onClick={() => close()}>
                <LeftOutlined />
                {$pageTitle}
              </Space>
            ) : (
              title
            )
          }
          tabProps={{
            type: 'editable-card',
            hideAdd: true,
            activeKey: location.pathname,
            onTabClick: key => history.push(key),
            onEdit: (key: any, action) => {
              close(key);
            },
          }}
          key={location.pathname}
          {...pageProps}
        >
          {children}
        </PageContainer>
      </TabsTemplateContext.Provider>
    </CommonCache>
  );
};

export const useTabTemplate = () => useContext(TabsTemplateContext);
