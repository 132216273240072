import _ from 'lodash';

export const time2minute = (timeStr?: string): number => {
  if (timeStr) {
    const [h = '0', m = '0'] = timeStr.split(':');
    return parseInt(h) * 60 + parseInt(m);
  }
  return 0;
};

export const minute2time = (minute?: number | string) => {
  if (minute) {
    let minuteNum = 0;
    if (typeof minute === 'string') {
      minuteNum = parseInt(minute);
    } else {
      minuteNum = minute;
    }

    const h = `${Math.floor(minuteNum / 60)}`;
    const m = `${minuteNum % 60}`;

    return `${_.padStart(h, 2, '0')}:${_.padStart(m, 2, '0')}`;
  }
  return '00:00';
};
