import {PageLoading} from '@ant-design/pro-layout';
import {useAsyncMemo} from 'hooks/useAsync';
import React, {useEffect, useRef, useState} from 'react';

export type CommonDataLoaderProps<T> = {
  request: (...args: any[]) => T;
  params?: any[];
  children: (data?: T, loading?: boolean) => React.ReactNode;
  fallback?: (data: T) => React.ReactNode;
  forceReader?: boolean;
};
export function CommonDataLoader<T>({
  request,
  params = [],
  forceReader,
  children,
}: CommonDataLoaderProps<T>) {
  const {result, loading} = useAsyncMemo(
    async () => await request(...params),
    [...params]
  );
  if (loading && !forceReader) {
    return <PageLoading />;
  }
  return <>{children(result, loading)}</>;
}
