import {ProFormText} from '@ant-design/pro-form';
import {AuthService} from '@common/api';
import {CustomModalForm} from 'components/Common/Form/ModalForm';
import {useApi} from 'hooks/useApi';
import React from 'react';

export const LoginResetPassword = ({
  children,
}: React.PropsWithChildren<any>) => {
  const resetPassword = useApi(AuthService.resetPassword, {
    successMessage: '已成功發送密碼重置信',
  });
  return (
    <CustomModalForm
      trigger={children}
      title="忘記密碼"
      onFinish={async values => {
        await resetPassword({requestBody: values});
        return true;
      }}
    >
      <ProFormText label="Email" name="email" />
    </CustomModalForm>
  );
};
