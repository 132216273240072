import {ProFormDependency, ProFormText} from '@ant-design/pro-form';
import {FrontendJkUserOffTimeService, FrontendMeService} from '@common/api';
import {Alert, Divider, Space} from 'antd';
import {CustomButton} from 'components/Common/Button';
import {CustomModalForm} from 'components/Common/Form/ModalForm';
import {useApi} from 'hooks/useApi';
import {useAsyncMemo} from 'hooks/useAsync';
import moment from 'moment';
import {useMemo, useState} from 'react';
import {Calendar, momentLocalizer} from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import {UserOffDatePlanBuy} from '../UserOffDatePlan/Buy';
export type BackendJKUserOffDateProps = {
  data: any;
};

const weeks = ['日', '一', '二', '三', '四', '五', '六'];
const localizer = momentLocalizer(moment);
const messages: any = {
  week: '週',
  work_week: 'Semaine de travail',
  day: '日',
  month: '月',
  previous: '←上一頁',
  next: '下一頁→',
  today: '今日',
  agenda: '行程',
  showMore: (total: number) => `+${total} 筆`,
  date: '日期',
  time: '時間',
  event: '備註',
};

export const BackendJKUserNewOffDate = ({data}: any) => {
  const getExpired = useApi(FrontendMeService.getMeOffDatePlan);
  const findAll = useApi(FrontendJkUserOffTimeService.findAll);
  const add = useApi(FrontendJkUserOffTimeService.add, {
    successMessage: '新增休假成功',
  });
  const remove = useApi(FrontendJkUserOffTimeService.delete, {
    successMessage: '移除休假成功',
  });
  const [isOpen, setIsOpen] = useState(false);

  const setting = useAsyncMemo(async () => {
    const result: any = {
      isEnabled: false,
      date: {},
      events: [],
      expired: '',
    };
    if (isOpen) {
      const expired = await getExpired();
      if (expired.data !== null) {
        result.isEnabled = true;
        const res = await findAll({where: {jkUserId: data.id}});
        if (expired.data !== '') {
          result.isEnabled = moment().isBefore(expired.data);
          result.expired = moment(expired.data).format('YYYY-MM-DD');
        }
        if (res) {
          result.events = res.data?.map(x => {
            const startStr = moment(x.startAt).format('HH:mm');
            const endStr = moment(x.endAt).format('HH:mm');
            const isFull = startStr === '00:00' && startStr === endStr;
            let note = x.note ? x.note : '無備註';
            if (isFull) {
              note += '(整日)';
            } else {
              note += `(${startStr}~${endStr})`;
            }
            return {
              id: x.id,
              start: new Date(x.startAt!),
              end: new Date(x.endAt!),
              title: note,
            };
          });
          // result.date = fp.pipe(
          //   fp.map(fp.update('offDate', x => moment(x).format('YYYY-MM-DD'))),
          //   fp.keyBy('offDate')
          // )(res.data) as any;
        }
      } else {
        result.isEnabled = false;
      }

      return result;
    }
    return undefined;
  }, [isOpen, data]);

  const offWeeks = useMemo(() => {
    if (data && data.weeks) {
      return data.weeks.map((x: any) => weeks.indexOf(x));
    }
    return [];
  }, [data]);
  return (
    <CustomModalForm
      title="休假設定"
      trigger={<CustomButton color="purple">休假設定</CustomButton>}
      params={{
        ...data,
        isOpen,
      }}
      onVisibleChange={setIsOpen}
      submitter={false}
      modalProps={{
        centered: false,
        style: {top: 10},
      }}
    >
      {setting.result ? (
        <>
          {setting.result?.isEnabled ? (
            <Space direction="vertical" style={{width: '100%'}}>
              <Alert message="點擊月曆或是拖拉都可以進行新增" />
              <Space>
                <NewOffDateAdd
                  onSubmit={async ({start, end, note}: any) => {
                    await add({
                      requestBody: {
                        jkUserId: data.id,
                        startAt: start,
                        endAt: end,
                        note,
                      },
                    });
                    await setting.execute();
                    return true;
                  }}
                />
              </Space>
              <Calendar
                localizer={localizer}
                events={setting.result?.events}
                onSelectSlot={async info => {
                  const note = window.prompt('休假備註');

                  if (note !== null) {
                    await add({
                      requestBody: {
                        jkUserId: data.id,
                        startAt: info.start.toString(),
                        endAt: info.end.toString(),
                        note: note || '',
                      },
                    });
                    await setting.execute();
                  }
                }}
                onSelectEvent={async (event: any) => {
                  console.log({event});
                  if (window.confirm('是否要刪除所選的休假日？')) {
                    await remove({id: event.id});
                    await setting.execute();
                  }
                }}
                onSelecting={() => {
                  console.log('selection');
                  return true;
                }}
                selectable
                messages={messages}
                style={{height: 'calc(90vh - 200px)'}}
              />
            </Space>
          ) : (
            <div>
              <Alert message="尚未啟用休假設定，是否要購買方案？" />
              <Divider />
              <UserOffDatePlanBuy />
            </div>
          )}
        </>
      ) : null}
    </CustomModalForm>
  );
};

const NewOffDateAdd = ({onSubmit}: any) => {
  return (
    <CustomModalForm
      title="新增自訂休假"
      trigger={<CustomButton type="primary">新增</CustomButton>}
      onFinish={onSubmit}
    >
      <ProFormText
        fieldProps={{type: 'datetime-local'}}
        label="開始"
        name="start"
        rules={[{required: true}]}
        initialValue={moment().format('YYYY-MM-DD 00:00')}
      />
      <ProFormDependency name={['start']}>
        {({start}) => {
          return (
            <ProFormText
              fieldProps={{type: 'datetime-local'}}
              label="結束"
              name="end"
              initialValue={moment().add(1, 'd').format('YYYY-MM-DD 00:00')}
              rules={[
                {required: true},
                {
                  validator: async (rule, value) => {
                    console.log({start, value});
                    if (new Date(start) > new Date(value)) {
                      throw new Error('開始不得晚於結束');
                    }
                  },
                },
              ]}
            />
          );
        }}
      </ProFormDependency>
      <ProFormText label="備註" name="note"></ProFormText>
    </CustomModalForm>
  );
};
