import {UploadProps} from 'antd';
import {UploadFile} from 'antd/lib/upload/interface';
import {fp} from '.';

type UploadOption = Partial<UploadFile<any>>;

export const toFileObj = fp.curryN(
  2,
  (filePath: string, options: UploadOption) => {
    return {
      name: 'file',
      response: {data: filePath},
      status: 'done',
      ...options,
    };
  }
);

export const toFilePath = fp.curryN(2, (index: number, fileObj: any) => {
  if (fileObj) {
    return fp.getOr('', `${index}.response.data`, fileObj);
  }
  return undefined;
});

export const fileObjToBlob = fp.curryN(1, (fileObj: any) => {
  if (fileObj.originFileObj) {
    return window.URL.createObjectURL(fileObj.originFileObj);
  }

  return fileObj.response.data;
});
