import {ProFormDigit, ProFormText} from '@ant-design/pro-form';
import {BackendOffDatePlanService} from '@common/api';
import {
  CommonManagerEdit,
  CommonManagerEditProps,
} from 'components/Common/Manager/Edit';
import {useApi} from 'hooks/useApi';

export type BackendOffDatePlanEditProps = CommonManagerEditProps;

export const BackendOffDatePlanEdit: React.FC<BackendOffDatePlanEditProps> =
  props => {
    const findOne = useApi(BackendOffDatePlanService.findOne);
    const add = useApi(BackendOffDatePlanService.add);
    const update = useApi(BackendOffDatePlanService.update);

    return (
      <CommonManagerEdit
        request={async () => {
          const id = props.data?.id;
          if (id) {
            const res = await findOne({id: id});
            return res!.data || {};
          } else {
            return {};
          }
        }}
        onFinish={async values => {
          const id = props.data?.id;
          if (id) {
            await update({id, requestBody: values});
          } else {
            await add({requestBody: values});
          }
        }}
        {...props}
      >
        <ProFormText label="方案名稱" name="title" rules={[{required: true}]} />
        <ProFormDigit
          label="有效天數"
          name="day"
          rules={[{required: true, type: 'number'}]}
        />
        <ProFormDigit
          label="金額"
          name="price"
          rules={[{required: true, type: 'number'}]}
        />
      </CommonManagerEdit>
    );
  };
