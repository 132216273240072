import ProTable, {ProColumnType, ProTableProps} from '@ant-design/pro-table';
import {withProps} from 'recompose';
import fp from 'lodash/fp';
import './index.css';
import {CheckOutlined} from '@ant-design/icons';
import React from 'react';

export function CommonTable(
  props: React.PropsWithChildren<ProTableProps<any, any>>
) {
  return <WithPropsTable {...props} />;
}

/**
 * @type {import('@ant-design/pro-table')}
 */
export const WithPropsTable = withProps<
  ProTableProps<any, any>,
  ProTableProps<any, any>
>(props => ({
  headerTitle: props.headerTitle || <span />,
  scroll:
    props.scroll || props.tableLayout === 'fixed'
      ? props.scroll
      : {
          x: 'max-content',
        },
  options:
    props.options === undefined
      ? {
          search: true,
        }
      : props.options,
  search: props.search
    ? {
        ...props.search,
        resetText: '',
        optionRender: (config, props, doms) => [doms[1]],
      }
    : false,
  bordered: true,
  rowClassName: (row, idx) => (idx % 2 ? 'table-row-2' : 'table-row-1'),
  request: props.request
    ? async (params, sort, filter) => {
        const {pageSize, current} = params;
        let result: any = {
          data: [],
          total: 0,
          success: true,
        };
        if (props.request) {
          result = await props.request(params, sort, filter);
        }
        if (result.data.length > 0) {
          result.data = result.data.map((row: any, idx: number) => ({
            ...row,
            $no: pageSize * (current - 1) + idx + 1,
          }));
        }
        return result;
      }
    : undefined,
}))(ProTable);

const overwriteColumn = (overwriteProps: ProColumnType<any>) => {
  return (props: ProColumnType<any> = {}) =>
    fp.merge(overwriteProps, props) as ProColumnType<any>;
};

CommonTable.Utils = {
  Pipe: fp.pipe,
  No: overwriteColumn({
    title: 'No.',
    dataIndex: '$no',
    hideInSearch: true,
    width: 50,
    render: (dom, row, idx) => {
      return row.$no ? dom : idx + 1;
    },
  }),
  Actions: overwriteColumn({
    title: '操作',
    hideInSearch: true,
  }),
  Sorter: overwriteColumn({sorter: true}),
  Boolean: overwriteColumn({
    // valueType: 'checkbox',
    render: (dom, row, index, action, schema) => {
      if (schema.dataIndex) {
        const checked = fp.getOr(false, schema.dataIndex, row);
        return checked ? 'V' : '-';
      }
      return '';
    },
  }),
  JoinFields: (keys: string[], token: string) =>
    overwriteColumn({
      render: (dom, row) => {
        const values = keys.map(x => row[x]);
        return values.join(token);
      },
    }),
};
