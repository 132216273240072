import {
  blue,
  red,
  green,
  grey,
  purple,
  yellow,
  orange,
} from '@ant-design/colors';
import _ from 'lodash';
import {merge} from 'lodash/fp';
import {withProps} from 'recompose';
import {CSSProperties} from 'styled-components';

export const globalColors = {
  themes: {
    blue: {
      bg: blue.primary,
      txt: '#fff',
    },
    red: {
      bg: red.primary,
      txt: '#fff',
    },
    green: {
      bg: green.primary,
      txt: '#fff',
    },
    yellow: {
      bg: yellow.primary,
      txt: '#000',
    },
    orange: {
      bg: orange.primary,
      txt: '#fff',
    },
    purple: {
      bg: purple.primary,
      txt: '#fff',
    },
  },
  colors: {
    blue,
    red,
    green,
    grey,
    purple,
    yellow,
    orange,
  },
};

export const getThemeStyle = (
  key?: keyof typeof globalColors.themes
): CSSProperties => {
  if (key) {
    const theme = globalColors.themes[key];
    if (theme) {
      return {
        backgroundColor: theme.bg,
        color: theme.txt,
      };
    }
  }

  return {};
};
export type WithColorProps = {
  theme?: keyof typeof globalColors.themes;
};

export const withColor = <Props>(Comp: any) =>
  withProps<Props & WithColorProps, Props & WithColorProps>((props: any) =>
    _.merge(
      {
        style: getThemeStyle(props.theme),
      },
      props
    )
  )(Comp);
