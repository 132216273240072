import {BackendConfigService} from '@common/api';
import {CommonManager} from 'components/Common/Manager';
import {genRequest} from 'libs/genRequest';
import withProps from 'recompose/withProps';
import {BackendConfigEdit} from './Edit';

const DetailLink = withProps({
  children: '使用記錄',
})(CommonManager.RowButton);

export const BackendConfigManager = () => {
  return (
    <CommonManager
      headerActions={[]}
      rowActions={[BackendConfigEdit]}
      request={genRequest(BackendConfigService.findAll)}
      columns={[
        {
          title: '名稱',
          dataIndex: 'name',
        },
        {
          title: '值',
          dataIndex: 'value',
        },
        {title: '備註', dataIndex: 'note'},
      ]}
    />
  );
};
