import ProLayout, {PageContainer, ProBreadcrumb} from '@ant-design/pro-layout';
import {Route} from '@ant-design/pro-layout/lib/typings';
import {Button, Layout} from 'antd';
import {useMemo} from 'react';
import {Link, useLocation} from 'react-router-dom';
import {PageTemplateProps} from '../core/DynamicPages';

/**
 * 前台 Template
 * 根據 template.page.pages 來產生 Top 選單. (功能較少僅先產生一層)
 */

const RootTemplate: React.FC<PageTemplateProps> = ({children, page}) => {
  const location = useLocation();

  const routes = useMemo(() => {
    return Object.values(page.pages)
      .sort((a, b) => a.orderNum - b.orderNum)
      .map(
        (x): Route => ({
          name: x.title,
          path: x.path,
        })
      );
  }, [page]);

  return <>{children}</>;
};

export default RootTemplate;
