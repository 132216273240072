import ProTable, {
  ActionType,
  ProColumns,
  ProColumnType,
  ProTableProps,
} from '@ant-design/pro-table';
import {Modal, Space} from 'antd';
import {CommonLink} from 'components/Common/Route/Link';
import {CommonTable} from 'components/Common/Table';
import fp from 'lodash/fp';
import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {CustomButton} from '../Button';
import * as ReactIs from 'react-is';
import {CommonManagerContext} from './Context';
import {useTabTemplate} from '../Tabs/Template';
import {TableRowSelection} from '@ant-design/pro-table/lib/typing';

type ActionElementType = React.ComponentType<any> | React.ReactNode;

type CommonManagerProps<T> = ProTableProps<T, any> & {
  headerActions?: ActionElementType[];
  rowActions?:
    | ActionElementType[]
    | ((row: any, act?: ActionType) => ActionElementType[]);
  onDelete?: (row: any) => Promise<void>;
};
export function CommonManager<T>({
  rowActions,
  headerActions,
  columns = [],
  rowKey = 'id',
  onDelete,
  rowSelection,
  request,
  params: outParams,
  ...tableProps
}: CommonManagerProps<T>) {
  const [selectedKeys, setSelectedKeys] = useState<any[]>([]);
  const [selectedRows, setSelectedRows] = useState<any[]>([]);
  const [params, setParams] = useState({});
  const [sort, setSort] = useState({});
  const actionType = useRef<ActionType>();
  const renderActions = useCallback(
    (actions: ActionElementType[], props: any, extra?: any) => {
      if (actions) {
        const $props = {
          ...props,
          params: {
            ...outParams,
            ...params,
          },
          sort,
          columns,
        };
        return (
          <Space>
            {actions.map(Action => {
              if (ReactIs.isValidElementType(Action)) {
                return <Action {...$props} />;
              } else {
                return React.cloneElement(Action as any, $props);
              }
            })}
            {extra}
          </Space>
        );
      }
      return [];
    },
    [params, columns, outParams]
  );

  const $columns = useMemo<ProColumns<any, any>[]>(() => {
    let actionsCount = rowActions?.length || 0;
    if (onDelete) {
      actionsCount++;
    }

    return [
      CommonTable.Utils.No(),
      {
        title: '功能',
        dataIndex: rowKey,
        width: rowActions ? actionsCount * 100 : undefined,
        hideInTable: actionsCount === 0,
        hideInSearch: true,
        render: (dom, row, idx, act) => {
          if (rowActions) {
            let actions = [];
            if (rowActions instanceof Function) {
              actions = rowActions(row, act);
            } else {
              actions = rowActions;
            }
            return renderActions(
              actions,
              {
                data: row,
                value: fp.get([rowKey as any], row),
                action: act,
              },
              onDelete && (
                <CustomButton.Del
                  onClick={async () => {
                    Modal.confirm({
                      content: '是否要刪除這筆資料？',
                      onOk: async () => {
                        await onDelete(row);
                        act?.clearSelected && act.clearSelected();
                        act?.reload && act.reload();
                      },
                    });
                  }}
                >
                  刪除
                </CustomButton.Del>
              )
            );
          }
          return null;
        },
      },
      ...columns,
    ] as ProColumns<any, any>[];
  }, [columns, rowActions, rowKey]);
  return (
    <CommonManagerContext.Provider
      value={{
        actionType: actionType,
      }}
    >
      <CommonTable
        rowSelection={
          rowSelection
            ? {
                selectedRowKeys: selectedKeys,
                onChange: (keys, rows) => {
                  setSelectedKeys(keys);
                  setSelectedRows(rows);
                },
              }
            : undefined
        }
        headerTitle={
          <Space>
            {headerActions &&
              renderActions(headerActions, {
                action: actionType.current,
                selectedKeys,
                selectedRows,
              })}
          </Space>
        }
        actionRef={actionType}
        columns={$columns}
        rowKey={rowKey}
        params={outParams}
        request={async (params, sort, filter) => {
          if (request) {
            setParams(params);
            setSort(sort);
            return await request?.(params, sort, filter);
          }
          return {
            total: 0,
            data: [],
          };
        }}
        {...tableProps}
      />
    </CommonManagerContext.Provider>
  );
}

type RowButtonProps = {
  data?: any;
  value?: any;
  prefix?: string;
  state?: any;
  Comp?: React.ComponentType;
};
CommonManager.RowButton = ({
  data,
  children,
  value,
  prefix = '/',
  state,
  Comp = CustomButton,
}: React.PropsWithChildren<RowButtonProps>) => {
  return (
    <CommonLink to={`${prefix}${value}`} state={state}>
      <Comp>{children}</Comp>
    </CommonLink>
  );
};

CommonManager.DeleteButton = ({
  children = '刪除',
  ...props
}: React.PropsWithChildren<any>) => {
  return <CustomButton.Del {...props}>{children}</CustomButton.Del>;
};
