import { request as __request } from '../core/request';
export class FrontendJkUserFreeLogService {
    /**
     * [BASE] Search From JKUserFreeLogEntity
     * @returns any Success
     * @throws ApiError
     */
    static findAll({ where, page, }) {
        return __request({
            method: 'GET',
            path: `/api/frontend/jk_user_free_log`,
            query: {
                'where': where,
                'page': page,
            },
        });
    }
    /**
     * [BASE] Batch Update To JKUserFreeLogEntity
     * @returns void
     * @throws ApiError
     */
    static batchUpdate({ requestBody, }) {
        return __request({
            method: 'PUT',
            path: `/api/frontend/jk_user_free_log`,
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * [BASE] FindOne From JKUserFreeLogEntity
     * @returns any Success
     * @throws ApiError
     */
    static findOne({ id, }) {
        return __request({
            method: 'GET',
            path: `/api/frontend/jk_user_free_log/${id}`,
            errors: {
                400: `Bad Request`,
            },
        });
    }
}
