import ProCard from '@ant-design/pro-card';
import ProDescriptions from '@ant-design/pro-descriptions';
import {BackendUserService} from '@common/api';
import {Space} from 'antd';
import {CommonDataLoader} from 'components/Common/DataLoader';
import {useApi} from 'hooks/useApi';
import moment from 'moment';
import {useParams} from 'react-router-dom';
import {JKUserFreeLogManager} from '../JKUser/FreeLog/Manager';
import {JKUserManager} from '../JKUser/Manager';
import {BackendPointCardManager} from '../PointCard/Manager';
import {BackendUserOffDatePlanManager} from '../UserOffDatePlan/Manager';
import {BackendUserEdit} from './Edit';

export const BackendUserDetail = () => {
  const {id} = useParams<any>();
  const findOne = useApi(BackendUserService.findOne);

  return (
    <Space direction="vertical" style={{width: '100%'}}>
      <CommonDataLoader
        request={async id => {
          const res = await findOne({id});
          return res.data;
        }}
        params={[id]}
        forceReader
      >
        {(data: any, loading) => (
          <ProCard
            loading={loading}
            title="會員基本資料"
            extra={[<BackendUserEdit data={data} />]}
          >
            <ProDescriptions>
              <ProDescriptions.Item label="暱稱">
                {data?.nickname}
              </ProDescriptions.Item>
              <ProDescriptions.Item label="帳號">
                {data?.username}
              </ProDescriptions.Item>
              <ProDescriptions.Item label="Email">
                {data?.email}
              </ProDescriptions.Item>
              <ProDescriptions.Item label="手機號碼">
                {data?.phone}
              </ProDescriptions.Item>
              <ProDescriptions.Item label="權限">
                {data?.role === 'USER' ? '一般使用者' : '管理員'}
              </ProDescriptions.Item>
              <ProDescriptions.Item label="是否停用">
                {data?.isDisabled ? '停用' : '啟用'}
              </ProDescriptions.Item>
              <ProDescriptions.Item label="置頂點數" valueType="digit">
                {data?.$currentPoint}
              </ProDescriptions.Item>
              <ProDescriptions.Item label="VIP休假到期日">
                {data?.isEnabledOffDate ? (
                  <div>
                    {moment(data?.offDateExpired).format('YYYY-MM-DD')}
                    (系統設定)
                  </div>
                ) : (
                  <span>
                    {moment(data?.$offDateExpired).format('YYYY-MM-DD')}
                  </span>
                )}
              </ProDescriptions.Item>
            </ProDescriptions>
          </ProCard>
        )}
      </CommonDataLoader>
      <ProCard tabs={{}}>
        <ProCard.TabPane tab="置頂方案購買紀錄" key="2">
          <BackendPointCardManager userId={id} />
        </ProCard.TabPane>
        <ProCard.TabPane tab="休假方案購買紀錄" key="4">
          <BackendUserOffDatePlanManager userId={id} />
        </ProCard.TabPane>
        <ProCard.TabPane tab="捷克會員" key="1">
          <JKUserManager userId={id} />
        </ProCard.TabPane>
        <ProCard.TabPane tab="置頂記錄" key="3">
          <JKUserFreeLogManager userId={id} />
        </ProCard.TabPane>
      </ProCard>
    </Space>
  );
};
