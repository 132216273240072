import {TabsTemplate} from 'components/Common/Tabs/Template';
import {withProps} from 'recompose';

export default withProps({
  tabs: {
    index: '常見問題',
    report: '問題回報',
  },
  title: '幫助中心',
  RWD: false,
})(TabsTemplate);
