import ProDescriptions from '@ant-design/pro-descriptions';
import {ModalForm, ProFormDependency, ProFormText} from '@ant-design/pro-form';
import {FrontendMeService} from '@common/api';
import {CustomProForm} from 'components/Common/Form';
import {CustomModalForm} from 'components/Common/Form/ModalForm';
import {useApi} from 'hooks/useApi';
import React from 'react';

export const BackendEditPassword = ({
  children,
}: React.PropsWithChildren<any>) => {
  const setPassword = useApi(FrontendMeService.setPassword, {
    successMessage: '修改密碼成功',
  });
  return (
    <CustomModalForm
      trigger={children}
      title="修改密碼"
      onFinish={async values => {
        await setPassword({requestBody: values});
        return true;
      }}
    >
      <ProFormText.Password
        name="oldPassword"
        label="舊密碼"
        rules={[{required: true}]}
      />
      <ProFormText.Password
        name="password"
        label="新密碼"
        rules={[{required: true}]}
      />
      <ProFormDependency name={['password']} shouldUpdate>
        {({password}) => (
          <ProFormText.Password
            name="confirmPassword"
            label="再次確認密碼"
            rules={[
              {
                required: true,
                validator: async (rule, value) => {
                  if (value !== password) {
                    throw new Error('兩次密碼不一致');
                  }
                },
              },
            ]}
          />
        )}
      </ProFormDependency>
    </CustomModalForm>
  );
};
