import {PageContainer} from '@ant-design/pro-layout';
import {Card} from 'antd';

const ProductDetail: React.FC = () => {
  return (
    <PageContainer title="商品">
      <Card>施工中</Card>
    </PageContainer>
  );
};

export const title = '商品內頁';

export default ProductDetail;
