import ProCard from '@ant-design/pro-card';
import {
  ProFormCheckbox,
  ProFormGroup,
  ProFormSwitch,
} from '@ant-design/pro-form';
import {BackendJkUserService} from '@common/api';
import {CustomButton} from 'components/Common/Button';
import {CustomModalForm} from 'components/Common/Form/ModalForm';
import {CommonTimeField} from 'components/Common/Form/TimeInput';
import {useApi} from 'hooks/useApi';
import {time2minute} from 'libs/time';
import fp from 'lodash/fp';

export const JKUserBatchUpdate = ({selectedKeys, action}: any) => {
  const batchUpdate = useApi(BackendJkUserService.batchUpdate);

  return (
    <CustomModalForm
      trigger={
        <CustomButton.Edit disabled={selectedKeys.length === 0}>
          批次更新
        </CustomButton.Edit>
      }
      title="捷克會員批次更新"
      onFinish={async payload => {
        const requestBody = fp.pipe(
          fp.update('startTime', time2minute),
          fp.update('endTime', time2minute)
        )(payload);
        await batchUpdate({
          requestBody: selectedKeys.map((x: any) => ({
            id: x,
            ...requestBody,
          })),
        });

        action.clearSelected();
        action.reload();

        return true;
      }}
    >
      <ProCard title="排程設定" bordered headerBordered type="inner">
        <ProFormSwitch
          name="isDisabled"
          label="暫停執行"
          checkedChildren="是"
          unCheckedChildren="否"
        />
        <ProFormGroup>
          <CommonTimeField
            label="開始時間"
            name="startTime"
            initialValue={'00:00'}
          />
          <CommonTimeField
            label="結束時間"
            name="endTime"
            initialValue={'23:59'}
          />
        </ProFormGroup>
        <ProFormCheckbox.Group
          label="星期"
          name="weeks"
          options={['日', '一', '二', '三', '四', '五', '六']}
          initialValue={['日', '一', '二', '三', '四', '五', '六']}
        />
      </ProCard>
    </CustomModalForm>
  );
};
