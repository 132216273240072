import { request as __request } from '../core/request';
export class PublicHtmlService {
    /**
     * @returns any Success
     * @throws ApiError
     */
    static getContent({ key, }) {
        return __request({
            method: 'GET',
            path: `/api/html/${key}`,
        });
    }
}
