import {PageProps, PagesObj} from '.';

export const isPage = (page: PagesObj | PageProps): page is PageProps => true;

export const filePath2UrlPath = (filePath: string, baseURL = '') => {
  const result = `${baseURL}${filePath}`
    .replace('./', '/')
    .replace('.tsx', '')
    .replace('/index', '');
  return result === '' ? '/' : result;
};
