import ProForm, {ProFormDependency, ProFormText} from '@ant-design/pro-form';
import {Alert, Button, Card, Col, Row, Space} from 'antd';
import {CustomProForm} from 'components/Common/Form';
import {FrontendLinearBg} from 'components/Frontend/LinearBg';
import {useCallback, useState} from 'react';
import {Box} from '../Common/Box';
import {LoginButton, LoginInput, LoginSubTitle, LoginTitle} from './Widget';
import {AuthService, UserEntityProfileAuth} from '@common/api';
import {useApi} from 'hooks/useApi';
import {useAuthorize} from 'core/Authorize';
import {LoginResetPassword} from './ResetPassword';

const initialData =
  process.env.NODE_ENV === 'development'
    ? {
        username: 'kenny',
        password: 'kenny',
      }
    : {};

export type LoginProps = {
  onLoginSuccess?: (data?: UserEntityProfileAuth) => void;
  onRegisterSuccess?: (data?: UserEntityProfileAuth) => void;
  isFull?: boolean;
};

/**
 * 基本登入元件，單純傳送 username / password 出來
 */
export const Login: React.FC<LoginProps> = ({
  onLoginSuccess,
  onRegisterSuccess,
  isFull,
}) => {
  const login = useApi(AuthService.login, {successMessage: '登入成功'});
  const register = useApi(AuthService.register, {successMessage: '註冊成功'});
  const {onFetch, user} = useAuthorize();
  const onLoginSubmit = useCallback(async values => {
    const {username, password} = values;
    const res = await login({
      requestBody: {
        username,
        password,
      },
    });
    onLoginSuccess && onLoginSuccess(res.data);
    onFetch && onFetch();
  }, []);

  const onRegisterSubmit = useCallback(async values => {
    const {username, password, email} = values;
    const res = await register({
      requestBody: {
        username,
        password,
        email,
      },
    });
    onRegisterSuccess && onRegisterSuccess(res.data);
    onFetch && onFetch();
  }, []);

  return (
    <FrontendLinearBg
      style={{
        height: isFull ? '100vh' : 'initial',
        width: isFull ? '100vw' : 'initial',
        padding: 24,
      }}
    >
      <Row
        align="middle"
        justify="space-around"
        gutter={48}
        style={{height: '100%'}}
      >
        {onLoginSuccess && (
          <Col span={24} md={8}>
            <CustomProForm
              onFinish={onLoginSubmit}
              submitter={{
                render: ({submit}) => (
                  <Space direction="vertical" style={{width: '100%'}}>
                    <LoginButton block onClick={submit}>
                      登入
                    </LoginButton>
                    <LoginResetPassword>
                      <Button block type="link" style={{color: '#fff'}}>
                        忘記密碼
                      </Button>
                    </LoginResetPassword>
                  </Space>
                ),
              }}
              initialValues={initialData}
            >
              <LoginTitle>登入</LoginTitle>
              <LoginInput
                label="帳號"
                name="username"
                rules={[{required: true}]}
              />
              <LoginInput
                label="密碼"
                name="password"
                rules={[{required: true}]}
                isPassword
              />
            </CustomProForm>
          </Col>
        )}
        {onRegisterSuccess && (
          <Col span={24} md={8}>
            <CustomProForm
              onFinish={onRegisterSubmit}
              submitter={{
                render: ({submit}) => (
                  <LoginButton block onClick={submit}>
                    註冊
                  </LoginButton>
                ),
              }}
            >
              <LoginTitle>註冊</LoginTitle>
              <LoginSubTitle>立即註冊 馬上使用</LoginSubTitle>
              <LoginInput
                label="帳號"
                name="username"
                rules={[{required: true}]}
              />
              <LoginInput
                label="密碼"
                isPassword
                name="password"
                rules={[{required: true}]}
              />
              <ProFormDependency name={['password']}>
                {({password}) => {
                  return (
                    <LoginInput
                      label="確認密碼"
                      isPassword
                      name="confirmPassword"
                      rules={[
                        {
                          required: true,
                        },
                        {
                          validator: async (rule: any, value: any) => {
                            if (value !== password) {
                              throw new Error('兩次密碼不一致');
                            }
                          },
                        },
                      ]}
                    />
                  );
                }}
              </ProFormDependency>
              <LoginInput
                label="Email"
                name="email"
                rules={[{required: true, type: 'email'}]}
              />
            </CustomProForm>
          </Col>
        )}
      </Row>
    </FrontendLinearBg>
  );
};
