import fp from 'lodash/fp';
export const genRequest = (apiHander: any) => {
  return async (params: any, sorter: any, filter: any) => {
    console.log('Gen Request', {params, sorter, filter});
    let $params = {...params};
    for (const key in sorter) {
      $params.sortKey = key.replace(/,/g, '.');
      $params.sortType = sorter[key] === 'ascend' ? 'ASC' : 'DESC';
    }
    $params = fp.omitBy(x => !x, $params);

    const sendParams = {
      ...$params,
      where: $params,
      page: $params,
    };
    console.log(sendParams);
    const res = await apiHander(sendParams);

    console.log('Table Request', res);
    return {
      data: res.data || [],
      total: res.total,
      success: true,
      extra: res.extra,
    };
  };
};
