import ProTable from '@ant-design/pro-table';
import {BackendStatsService} from '@common/api';
import {Space, Typography} from 'antd';
import {CustomButton} from 'components/Common/Button';
import {CommonTable} from 'components/Common/Table';
import {useApi} from 'hooks/useApi';
import moment from 'moment';
import {useState} from 'react';

export const BackendStatsOrders = () => {
  const getRows = useApi(BackendStatsService.getOrderStats);
  const [total, setTotal] = useState<number>();
  const [params, setParams] = useState<any>({});

  return (
    <ProTable
      headerTitle={
        <Space align="center">
          <Typography.Title type="danger" level={3} style={{margin: 0}}>
            總金額：{total}
          </Typography.Title>
          <CustomButton
            onClick={async () => {
              await getRows({
                ...params,
                download: 'excel',
              });
            }}
          >
            匯出Excel
          </CustomButton>
        </Space>
      }
      columns={[
        {
          title: '查詢區間',
          hideInTable: true,
          key: 'range',
          valueType: 'dateRange',
          initialValue: [moment().subtract('1', 'M').toDate(), new Date()],
        },
        CommonTable.Utils.Sorter({
          title: '類別',
          dataIndex: 'type',
          search: false,
        }),
        CommonTable.Utils.Sorter({
          title: '帳號',
          dataIndex: 'username',
          search: false,
        }),
        CommonTable.Utils.Sorter({
          title: '使用者暱稱',
          dataIndex: 'nickname',
          search: false,
        }),
        CommonTable.Utils.Sorter({
          title: '購買日期',
          dataIndex: 'createdAt',
          valueType: 'date',
          search: false,
        }),
        CommonTable.Utils.Sorter({
          title: '購買金額',
          dataIndex: 'amount',
          valueType: 'digit',
          search: false,
        }),
        CommonTable.Utils.Sorter({
          title: '購買方案',
          dataIndex: 'note',
          search: false,
        }),
      ]}
      request={async (params, sort) => {
        const sortKey = Object.keys(sort)[0];
        const $params = {
          startAt: params.range[0],
          endAt: params.range[1],
          sortKey,
          sortType: sort[sortKey] === 'ascend' ? 'asc' : 'desc',
        };
        const res = await getRows($params);
        setTotal(res.sum);
        setParams($params);
        return {
          data: res.data,
          total: res.total,
        };
      }}
    />
  );
};
