import _ from 'lodash';
import fp from 'lodash/fp';
import React, {Suspense} from 'react';
import {useMemo} from 'react';
import {BrowserRouter, Switch} from 'react-router-dom';
import {CoreDynamicPagesContext} from './Context';
import {createTemp} from './createRoutes';
import {filePath2UrlPath} from './utils';
export type CoreDynamicPagesProps = {
  /** WebPack.Context */
  context: __WebpackModuleApi.RequireContext;
  /** 選單/頁面過濾 */
  onFilterPage?: (path: string) => boolean;
  baseURL?: string;
};

export type PageProps = {
  filePath: string;
  title: string;
  component?: React.ComponentType<any>;
  path: string;
  pages: {
    [key: string]: PageProps;
  };
  orderNum: number;
};

export type PageTemplateProps = {
  page: PageProps;
};
export type PageComponentProps = {};

export type PagesObj = {
  [key: string]: PageProps;
};

const getPage = (
  context: __WebpackModuleApi.RequireContext,
  baseURL: string
) => {
  const processPages = (obj: any): PageProps => {
    if (obj.pages) {
      if (obj.pages.$template) {
        obj.component = obj.pages.$template.imported.default;
      }
      delete obj.pages.$template;
      for (const page in obj.pages) {
        obj.pages[page] = processPages(obj.pages[page]);
      }
      if (obj.pages.index) {
        obj = _.assign(obj, _.omit(obj.pages.index, ['pages', 'component']));
      }
    }
    if (obj.imported) {
      obj.component = obj.imported.default;
      obj.path = filePath2UrlPath(obj.filePath, baseURL);
      obj = _.assign(obj, _.omit(obj.imported, ['default']));
      delete obj.imported;
    }
    return obj;
  };
  const keys = context.keys();
  const importedList = keys.map(x => {
    console.log(x);
    return {
      filePath: x,
      imported: context(x),
    };
  });
  const importedObj: any = importedList.reduce((curr, x, idx) => {
    return _.set(
      curr,
      _.dropRight(
        _.flatMap(
          x.filePath.replace('./', '').replace('.tsx', '').split('/'),
          x => [x, 'pages']
        ),
        1
      ),
      x
    );
  }, {});
  return processPages({
    title: '',
    pages: importedObj,
    path: '/',
  });
};

export const CoreDynamicPages: React.FC<CoreDynamicPagesProps> = ({
  context,
  baseURL = '',
}) => {
  const page = useMemo<PageProps>(() => {
    return getPage(context, baseURL);
  }, [context, baseURL]);

  return (
    <CoreDynamicPagesContext.Provider value={{page}}>
      <Suspense fallback={<div>Loading...</div>}>
        {createTemp(page, [baseURL])}
      </Suspense>
    </CoreDynamicPagesContext.Provider>
  );
};
