import {ActionType} from '@ant-design/pro-table';
import {BackendSupportService} from '@common/api';
import {Button, ButtonProps} from 'antd';
import {CommonManager} from 'components/Common/Manager';
import {CommonTable} from 'components/Common/Table';
import {useApi} from 'hooks/useApi';
import {genRequest} from 'libs/genRequest';
import React from 'react';

const UpdateStatusButton: React.FC<
  ButtonProps & {status: string; data?: any; action?: ActionType}
> = ({status, data, action, ...props}) => {
  const update = useApi(BackendSupportService.update);
  return (
    <Button
      {...props}
      type={data.status === status ? 'primary' : 'dashed'}
      onClick={async () => {
        await update({
          id: data.id,
          requestBody: {
            status,
          },
        });
        action?.reload();
      }}
    ></Button>
  );
};

export const BackendSupportManager = () => {
  const getList = useApi(BackendSupportService.findAll);

  const del = useApi(BackendSupportService.delete);
  return (
    <CommonManager
      rowActions={[
        <UpdateStatusButton status="OPEN">等待回覆</UpdateStatusButton>,
        <UpdateStatusButton status="REPLY">已回覆</UpdateStatusButton>,
        // <UpdateStatusButton status="CLOSE">已關閉</UpdateStatusButton>,
      ]}
      request={genRequest(getList)}
      params={{sortKey: 'id', sortType: 'DESC'}}
      onDelete={async row => {
        await del(row);
      }}
      columns={[
        CommonTable.Utils.Sorter({
          title: '回報時間',
          dataIndex: 'createdAt',
          valueType: 'date',
        }),
        CommonTable.Utils.Sorter({
          title: '回報人',
          dataIndex: ['user', 'username'],
        }),
        CommonTable.Utils.Sorter({
          title: '聯絡信箱',
          dataIndex: 'email',
          render: dom => (
            <a href={`mailto:${dom}`} target="_blank">
              {dom}
            </a>
          ),
        }),
        CommonTable.Utils.Sorter({title: '回報問題', dataIndex: 'content'}),
        CommonTable.Utils.Sorter({
          title: '狀態',
          dataIndex: 'status',
          valueEnum: {
            OPEN: '等待回覆',
            REPLY: '已回覆',
            CLOSE: '已關閉',
          },
        }),
      ]}
    />
  );
};
