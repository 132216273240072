import {PageLoading} from '@ant-design/pro-layout';
import {
  FrontendPlanService,
  UserEntityProfileAuth,
  PublicHtmlService,
  PublicPlanService,
} from '@common/api';
import {Col, Image, Row, Space, Carousel, Button} from 'antd';
import {useApi} from 'hooks/useApi';
import {useAsyncMemo} from 'hooks/useAsync';
import fp from 'lodash/fp';
import pipe from 'p-pipe';
import {FrontendButton} from '../Button';
import {
  FrontendHomeBanner,
  FrontendHomeDesc,
  FrontendHomeTitle,
  FrontendPlanCard,
  FrontendSectionTitle,
} from './Widget';

import Slider from 'react-slick';
import {ArrowLeftOutlined} from '@ant-design/icons';
import {flex} from 'styled-system';
import {Login} from 'components/Login';
import {FrontendLinearBg} from '../LinearBg';
import {useAuthorize} from 'core/Authorize';
import {CommonLink} from 'components/Common/Route/Link';
import {useCallback, useRef} from 'react';
import styled from 'styled-components';
import {useResponsive} from 'hooks/useResponsive';

const HtmlView = styled.div`
  max-width: 80vw;
  img {
    max-width: 100%;
    height: auto;
  }
`;

export const FrontendHome = () => {
  const {user} = useAuthorize<UserEntityProfileAuth>();
  const getPlans = useApi(PublicPlanService.getList);
  const getHtml = useApi(PublicHtmlService.getContent);
  const plans = useAsyncMemo(pipe(getPlans, fp.get('data')), []);
  const loginPanel = useRef<HTMLDivElement>(null);
  const {isTabletOrMobile} = useResponsive();
  const html = useAsyncMemo(async () => {
    return await pipe(
      getHtml,
      fp.get('data.content')
    )({key: 'FRONTEND_CONTENT'});
  }, []);

  const gotoLogin = useCallback(() => {
    loginPanel.current?.scrollIntoView();
  }, []);

  return (
    <div>
      <FrontendHomeBanner>
        <Row
          justify="center"
          gutter={isTabletOrMobile ? 0 : 48}
          style={{padding: '48px 0px'}}
          wrap={true}
          align={isTabletOrMobile ? 'middle' : 'top'}
        >
          <Col
            span={12}
            md={3}
            style={{textAlign: isTabletOrMobile ? 'center' : 'right'}}
          >
            <Image
              src="/static/images/logo_l.png"
              preview={false}
              width={isTabletOrMobile ? undefined : 100}
            />
          </Col>
          <Col
            span={24}
            md={16}
            style={isTabletOrMobile ? {textAlign: 'center'} : {}}
          >
            <FrontendHomeTitle>
              捷克論壇我有空
              <br />
              自動化系統
            </FrontendHomeTitle>
            <FrontendHomeDesc style={isTabletOrMobile ? {fontSize: 16} : {}}>
              您是否每天浪費大量時間手動點擊「我有空」？
              <br />
              把點擊的事交給我們，讓您的帖子隨時保持「現在有空」
              <br />
              心動不如馬上使用！
              <br />
            </FrontendHomeDesc>
            {user ? (
              <Space>
                {user.username} 歡迎您！
                <CommonLink to="/admin">
                  <FrontendButton style={{minWidth: 120}}>
                    進入管理後台
                  </FrontendButton>
                </CommonLink>
              </Space>
            ) : (
              <Space>
                <FrontendButton style={{minWidth: 120}} onClick={gotoLogin}>
                  登入
                </FrontendButton>
                <FrontendButton style={{minWidth: 120}} onClick={gotoLogin}>
                  註冊
                </FrontendButton>
              </Space>
            )}
          </Col>
        </Row>

        {plans.loading || !plans.result ? (
          <PageLoading />
        ) : (
          <div
            style={{
              padding: '0px 40px',
              backgroundColor: 'rgba(0, 0, 0, 0.2)',
              height: 180,
            }}
          >
            <Slider
              slidesToShow={Math.min(
                isTabletOrMobile ? 1 : 4,
                plans.result.length
              )}
              infinite
              swipe
              rows={1}
              slidesToScroll={1}
            >
              {plans?.result?.map(x => (
                <FrontendPlanCard data={x} key={x.id as number} />
              ))}
            </Slider>
          </div>
        )}
      </FrontendHomeBanner>

      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
          padding: 48,
        }}
      >
        {/* <FrontendSectionTitle>使用說明</FrontendSectionTitle> */}
        {html.loading || !html.result ? (
          <PageLoading />
        ) : (
          <HtmlView dangerouslySetInnerHTML={{__html: html.result}}></HtmlView>
        )}
      </div>
      <div ref={loginPanel}>
        {!user && (
          <Login onLoginSuccess={() => {}} onRegisterSuccess={() => {}} />
        )}
      </div>

      <FrontendLinearBg>
        <div
          style={{
            padding: 24,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Image src="/static/images/logo_l.png" preview={false} />
          <a href="https://auto-jk.com" style={{color: '#fff', fontSize: 18}}>
            auto-jk.com
          </a>
        </div>
      </FrontendLinearBg>
    </div>
  );
};
