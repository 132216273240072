import { request as __request } from '../core/request';
export class FrontendUserOffDatePlanService {
    /**
     * [BASE] Search From UserOffDatePlanEntity
     * @returns any Success
     * @throws ApiError
     */
    static findAll({ where, page, }) {
        return __request({
            method: 'GET',
            path: `/api/frontend/off_date_plans`,
            query: {
                'where': where,
                'page': page,
            },
        });
    }
    /**
     * [BASE] Batch Update To UserOffDatePlanEntity
     * @returns void
     * @throws ApiError
     */
    static batchUpdate({ requestBody, }) {
        return __request({
            method: 'PUT',
            path: `/api/frontend/off_date_plans`,
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * 儲值
     * @returns any Success
     * @throws ApiError
     */
    static buy({ requestBody, }) {
        return __request({
            method: 'POST',
            path: `/api/frontend/off_date_plans/buy`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * [BASE] FindOne From UserOffDatePlanEntity
     * @returns any Success
     * @throws ApiError
     */
    static findOne({ id, }) {
        return __request({
            method: 'GET',
            path: `/api/frontend/off_date_plans/${id}`,
            errors: {
                400: `Bad Request`,
            },
        });
    }
}
