import {CheckCard} from '@ant-design/pro-card';
import ProForm from '@ant-design/pro-form';
import {
  FrontendOffDatePlanService,
  FrontendUserOffDatePlanService,
  PepayFormBody,
} from '@common/api';
import {CustomProForm} from 'components/Common/Form';
import {useApi} from 'hooks/useApi';
import {useAsyncMemo} from 'hooks/useAsync';
import {useState} from 'react';
import {BackendPepayForm} from '../PepayForm';

export const UserOffDatePlanBuy = () => {
  const [pepayForm, setPepayForm] = useState<PepayFormBody>();
  const find = useApi(FrontendOffDatePlanService.findAll);
  const buy = useApi(FrontendUserOffDatePlanService.buy);
  const items = useAsyncMemo(async () => {
    const res = await find({});
    return res.data || [];
  }, []);
  if (pepayForm) {
    return <BackendPepayForm data={pepayForm} />;
  }
  return (
    <CustomProForm
      onFinish={async values => {
        const res = await buy({requestBody: values});
        if (res.data) {
          setPepayForm(res.data);
        }
      }}
    >
      <ProForm.Item label="選擇購買方案" name="planId">
        <CheckCard.Group>
          {items.result?.map(x => (
            <CheckCard
              title={x.title}
              description={`${x.day}天 / NT.${x.price}`}
              value={x.id}
            />
          ))}
        </CheckCard.Group>
      </ProForm.Item>
    </CustomProForm>
  );
};
