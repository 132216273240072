import {useApi} from 'hooks/useApi';
import {FrontendSupportService} from '@common/api';
import {CommonManager} from 'components/Common/Manager';
import {CommonTable} from 'components/Common/Table';
import {BackendSupportReport} from './Report';
import {genRequest} from 'libs/genRequest';
export const BackendSupportCenter = () => {
  const getList = useApi(FrontendSupportService.findAll);
  const del = useApi(FrontendSupportService.delete);
  return (
    <CommonManager
      headerActions={[BackendSupportReport]}
      rowActions={[]}
      request={genRequest(getList)}
      params={{sortKey: 'id', sortType: 'DESC'}}
      onDelete={async row => {
        await del(row);
      }}
      columns={[
        CommonTable.Utils.Sorter({
          title: '回報時間',
          dataIndex: 'createdAt',
          valueType: 'date',
        }),
        CommonTable.Utils.Sorter({title: '回報問題', dataIndex: 'content'}),
        CommonTable.Utils.Sorter({
          title: '狀態',
          dataIndex: 'status',
          valueEnum: {
            OPEN: '等待回覆',
            REPLY: '已回覆',
            CLOSE: '已關閉',
          },
        }),
      ]}
    />
  );
};
