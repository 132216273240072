import {
  ProFormDatePicker,
  ProFormDependency,
  ProFormGroup,
  ProFormSelect,
  ProFormSwitch,
  ProFormText,
} from '@ant-design/pro-form';
import {BackendUserService} from '@common/api';
import {FormInstance} from 'antd/es/form';
import {
  CommonManagerEdit,
  CommonManagerEditProps,
} from 'components/Common/Manager/Edit';
import {useApi} from 'hooks/useApi';
import moment from 'moment';
import React, {useRef} from 'react';

export const BackendUserEdit = (
  props: React.PropsWithChildren<CommonManagerEditProps>
) => {
  const add = useApi(BackendUserService.add);
  const edit = useApi(BackendUserService.update);
  const formRef = useRef<FormInstance>();

  return (
    <CommonManagerEdit
      formRef={formRef}
      onFinish={async values => {
        if (props.data) {
          await edit({
            id: props?.data?.id,
            requestBody: values,
          });
        } else {
          await add({
            requestBody: values,
          });
        }
      }}
      onValuesChange={(c, v) => {
        if (c.isEnabledOffDate !== undefined) {
          if (c.isEnabledOffDate) {
            formRef.current?.setFieldsValue({
              offDateExpired: moment().add(1, 'M').toDate(),
            });
          } else {
            formRef.current?.setFieldsValue({
              offDateExpired: undefined,
            });
          }
        }
      }}
      request={async () => {
        return (
          props?.data || {
            role: 'USER',
          }
        );
      }}
      {...props}
    >
      <ProFormGroup>
        <ProFormText label="暱稱" name="nickname" />
      </ProFormGroup>
      <ProFormGroup>
        <ProFormText label="帳號" name="username" rules={[{required: true}]} />
        <ProFormText.Password
          label="密碼"
          name="password"
          rules={[{required: !props.data}]}
          placeholder={props.data ? '輸入後即重置密碼' : undefined}
        />
      </ProFormGroup>
      <ProFormGroup>
        <ProFormText label="Email" name="email" rules={[{required: true}]} />
        <ProFormSwitch label="已驗證" name="emailVerify" />
      </ProFormGroup>
      <ProFormGroup>
        <ProFormSwitch
          label="啟用通知"
          tooltip="當錯誤次數達到上限時，啟用簡訊通知"
          name="isSendNotify"
        ></ProFormSwitch>
        <ProFormDependency name={['isSendNotify']}>
          {({isSendNotify}) => (
            <ProFormText
              label="手機號碼"
              name="phone"
              disabled={!isSendNotify}
              rules={[{required: isSendNotify}]}
            />
          )}
        </ProFormDependency>
      </ProFormGroup>
      <ProFormGroup>
        <ProFormSwitch
          label="啟用VIP休假方案"
          tooltip="打開後即可直接使用休假方案，無需購買"
          name="isEnabledOffDate"
        />
        <ProFormDependency name={['isEnabledOffDate']}>
          {({isEnabledOffDate}) => (
            <ProFormDatePicker
              label="啟用至"
              tooltip="設定啟用VIP的期限"
              name="offDateExpired"
              disabled={!isEnabledOffDate}
            />
          )}
        </ProFormDependency>
      </ProFormGroup>
      <ProFormGroup>
        <ProFormSelect
          label="權限"
          name="role"
          options={[
            {
              label: '試用會員',
              value: 'TRIAL_USER',
            },
            {
              label: '一般使用者',
              value: 'USER',
            },
            {
              label: '管理員',
              value: 'ADMIN',
            },
          ]}
          rules={[{required: true}]}
        />
        <ProFormSwitch label="帳號停用" name="isDisabled"></ProFormSwitch>
      </ProFormGroup>
    </CommonManagerEdit>
  );
};
