import {useContext, useState} from 'react';
import React, {useCallback, useMemo, useEffect} from 'react';
import fp from 'lodash/fp';
import {debounce} from 'lodash';

const CommonCacheContext = React.createContext<{
  state: any;
  setState: React.Dispatch<React.SetStateAction<any>>;
}>({
  state: {},
  setState: () => ({}),
});

export const CommonCache = ({
  children,
  state,
  setState,
}: React.PropsWithChildren<any>) => {
  return (
    <CommonCacheContext.Provider
      value={{state, setState: debounce(setState, 200)}}
    >
      {children}
    </CommonCacheContext.Provider>
  );
};

export const useCache = (key?: string, initialState?: any) => {
  const {state, setState} = useContext(CommonCacheContext);

  useEffect(() => {
    if (key) {
      setState(
        fp.update(key, data => {
          if (!data) {
            return initialState;
          }
          return data;
        })
      );
    }
  }, [key]);

  const $state = useMemo(() => {
    if (key) {
      return fp.getOr(initialState, key, state);
    }
    return undefined;
  }, [state, key]);

  const $setState = useCallback((stateOrCb: any) => {
    if (key) {
      if (typeof stateOrCb === 'function') {
        setState(fp.pipe(fp.update(key, stateOrCb)));
      } else {
        setState(fp.pipe(fp.set(key, stateOrCb)));
      }
    }
  }, []);

  return [$state, $setState];
};
