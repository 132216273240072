import {FormInstance, ModalForm, ModalFormProps} from '@ant-design/pro-form';
import {ActionType} from '@ant-design/pro-table';
import {CustomButton} from 'components/Common/Button';
import React, {useContext, useEffect, useRef, useState} from 'react';
import {CommonManagerContext} from './Context';

export type CommonManagerEditProps<T = any> = ModalFormProps & {
  data?: T;
  type?: 'ADD' | 'EDIT';
  action?: ActionType;
};

export function CommonManagerEdit({
  data,
  type = 'ADD',
  onFinish,
  formRef: $formRef,
  ...modalFormProps
}: React.PropsWithChildren<CommonManagerEditProps>) {
  const [key, setKey] = useState(new Date().getTime());
  const {actionType} = useContext(CommonManagerContext);
  const innerFormRef = useRef<FormInstance>();
  const formRef = $formRef || innerFormRef;

  useEffect(() => {
    setTimeout(() => {
      setKey(new Date().getTime());
    });
  }, [data]);
  return (
    <ModalForm
      key={key}
      title={data ? '編輯' : '新增'}
      trigger={
        !data ? (
          <CustomButton.Add>新增</CustomButton.Add>
        ) : (
          <CustomButton.Edit>編輯</CustomButton.Edit>
        )
      }
      formRef={formRef}
      modalProps={{
        afterClose: () => {
          formRef.current?.resetFields();
        },
      }}
      onFinish={async values => {
        console.log('onFinish');
        let result;
        if (onFinish) {
          result = await onFinish(values);
        }
        console.log({result});
        if (result === undefined) {
          result = true;
        }
        actionType?.current?.reload();
        actionType?.current?.clearSelected?.();
        return result;
      }}
      {...modalFormProps}
    ></ModalForm>
  );
}
