import {BackendLayout} from 'components/Backend/Layout';

export default BackendLayout;
// import {useState} from 'react';
// import {Login} from '../components/Login';
// import {CoreAuthorize} from '../core/Authorize';
// import ProLayout from '@ant-design/pro-layout';
// import {PageTemplateProps} from '../core/DynamicPages';
// import {useMemo} from 'react';
// import {Route} from '@ant-design/pro-layout/lib/typings';
// import {Link, useLocation} from 'react-router-dom';
// import {Badge, Button, Dropdown, Menu, Space} from 'antd';
// import {NotificationOutlined, UserOutlined} from '@ant-design/icons';
// import _ from 'lodash';

// /**
//  * 後台 Template
//  * 根據 template.page.pages 來產生選單，由於目前的功能較少，就只先產生第一層，若要產生到第二層，還需要規劃一下相關的功能
//  */

// const AdminTemplate: React.FC<PageTemplateProps> = ({children, page}) => {
//   const [user, setUser] = useState<any>({});
//   const location = useLocation();
//   const routes = useMemo<Route[]>(() => {
//     if (page.pages) {
//       return _.sortBy(Object.values(page.pages), 'orderNum').map(x => ({
//         name: x.title,
//         path: x.path,
//         key: x.path,
//       }));
//     }
//     return [];
//   }, [page]);

//   return (
//     <CoreAuthorize user={user}>
//       {user ? (
//         <ProLayout
//           route={{
//             path: '/admin',
//             routes: routes,
//           }}
//           location={location}
//           menuItemRender={(x, dom) =>
//             x.path ? <Link to={x.path}>{dom}</Link> : dom
//           }
//           rightContentRender={() => (
//             <Space>
//               <Dropdown
//                 overlay={
//                   <Menu>
//                     <Menu.Item>修改個人資料</Menu.Item>
//                     <Menu.Divider />
//                     <Menu.Item>登出</Menu.Item>
//                   </Menu>
//                 }
//               >
//                 <Button size="small" icon={<UserOutlined />}>
//                   UserName
//                 </Button>
//               </Dropdown>
//               <Badge count={10}>
//                 <Button icon={<NotificationOutlined />} size="small"></Button>
//               </Badge>
//             </Space>
//           )}
//         >
//           {children}
//         </ProLayout>
//       ) : (
//         <Login
//           onSubmit={async () => {
//             setUser({});
//             return [];
//           }}
//         />
//       )}
//     </CoreAuthorize>
//   );
// };

// export default AdminTemplate;
