import {BackendUserPointCardService} from '@common/api';
import {Button} from 'antd';
import {CommonManager} from 'components/Common/Manager';
import {CommonTable} from 'components/Common/Table';
import {useApi} from 'hooks/useApi';
import {genRequest} from 'libs/genRequest';
import {useState} from 'react';
import {
  BackendOrderAccept,
  BackendOrderCancel,
  BackendOrderDelete,
} from './Actions';

const ExportExcel: React.FC<any> = ({params, columns = [], sort}) => {
  const downloadExcel = useApi(BackendUserPointCardService.exportExcel);
  return (
    <Button
      onClick={async () => {
        const res = await downloadExcel({
          requestBody: {
            where: params,
            keyword: params.keyword,
            sortKey: params.sortKey,
            sortType: params.sortType,
            columns,
          },
        });
        console.log(res);
      }}
    >
      匯出Excel
    </Button>
  );
};

export const BackendOrderManager = () => {
  const findAll = useApi(BackendUserPointCardService.findAll);
  const [extra, setExtra] = useState<any>({});
  return (
    <CommonManager
      request={async (params, sort, filter) => {
        const result = await genRequest(findAll)(params, sort, filter);
        setExtra(result.extra);
        return result;
      }}
      headerActions={[
        ExportExcel,
        // <span>
        //   <Tooltip overlay="僅計算「使用者儲值」的總計">
        //     總計：{extra.sum || 0}
        //   </Tooltip>
        // </span>,
      ]}
      rowActions={row => {
        if (row.status === 'CREATE') {
          return [BackendOrderAccept, BackendOrderCancel];
        }

        return [BackendOrderDelete];
      }}
      params={{
        sortKey: 'id',
        sortType: 'DESC',
      }}
      search={{}}
      columns={[
        CommonTable.Utils.Sorter({
          title: '查詢區間',
          dataIndex: 'createdAt$Range',
          valueType: 'dateRange',
          hideInSearch: false,
          hideInTable: true,
        }),
        CommonTable.Utils.Sorter({
          title: '使用者帳號',
          dataIndex: ['user', 'username'],
          hideInSearch: true,
        }),
        CommonTable.Utils.Sorter({
          title: '使用者暱稱',
          dataIndex: ['user', 'nickname'],
          hideInSearch: true,
        }),
        CommonTable.Utils.Sorter({
          title: '購買日期',
          dataIndex: 'createdAt',
          valueType: 'date',
          hideInSearch: true,
        }),
        CommonTable.Utils.Sorter({
          title: '訂單類型',
          valueEnum: {
            BUY: '使用者儲值',
            ADMIN: '管理員派發',
            AUTO: '系統派發',
          },
          dataIndex: 'type',
        }),
        CommonTable.Utils.Sorter({
          title: '訂單狀態',
          dataIndex: 'status',
          valueEnum: {
            CREATE: '等待付款(已建立訂單)',
            RECEIVE1: '等待付款(等待使用者付費)',
            RECEIVE2: '已完成',
            CANCEL: '取消訂單',
          },
          valueType: 'select',
        }),
        CommonTable.Utils.Sorter({
          title: '購買方案',
          dataIndex: ['orderItem'],
          hideInSearch: true,
        }),
        CommonTable.Utils.Sorter({
          title: '購買金額',
          dataIndex: 'amount',
          hideInSearch: true,
        }),
      ]}
    />
  );
};
