import React, {useMemo} from 'react';
import {Link, useRouteMatch} from 'react-router-dom';

type CommonLinkProps = {
  to: string;
  state?: any;
};

export const CommonLink = ({
  to,
  children,
  state,
}: React.PropsWithChildren<CommonLinkProps>) => {
  const match = useRouteMatch();
  const href = useMemo(() => {
    return `${match.url}${to}`.replace(/\/{2,}/, '/');
  }, [match, to]);
  return (
    <Link
      to={{
        pathname: href,
        state,
      }}
    >
      {children}
    </Link>
  );
};
