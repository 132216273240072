import {PageLoading} from '@ant-design/pro-layout';
import {FrontendFaqService} from '@common/api';
import {Alert, Collapse} from 'antd';
import {useApi} from 'hooks/useApi';
import {useAsyncMemo} from 'hooks/useAsync';
import styled from 'styled-components';

const Wrap = styled.div`
  img {
    max-width: 100% !important;
  }
`;

export const BackendFAQView = () => {
  const findFAQ = useApi(FrontendFaqService.findAll);
  const items = useAsyncMemo(async () => {
    const res = await findFAQ({});
    return res.data;
  }, []);

  if (items.loading) {
    return <PageLoading />;
  }
  if (items.result && items.result.length === 0) {
    return <Alert type="warning" message="沒有任何FAQ" />;
  }
  return (
    <Collapse>
      {items.result?.map(x => (
        <Collapse.Panel header={x.title} key={x.id as number}>
          <Wrap dangerouslySetInnerHTML={{__html: x.content || ''}}></Wrap>
        </Collapse.Panel>
      ))}
    </Collapse>
  );
};
