import {CancelablePromise} from '@common/api';
import {notification} from 'antd';
import _ from 'lodash';
import {useCallback} from 'react';

type Options = {
  disabledNotify: boolean;
  successMessage: string;
  errorMessage: string;
  transform: (res: any) => any;
  memoize: boolean;
};

/** 用於統一處理Api的Response Data 以及 Handle Error */
export const useApi = <T extends any>(
  fn: T,
  {
    disabledNotify = false,
    successMessage,
    errorMessage,
    transform,
    memoize,
  }: Partial<Options> = {}
): T => {
  const callback = useCallback<any>(
    async (...props: any) => {
      if (fn instanceof Function) {
        let res: any = null;
        let err: any = null;
        try {
          if (memoize) {
            res = _.memoize(fn as any)(...props);
          } else {
            res = fn(...props);
          }

          if (res instanceof Promise || res instanceof CancelablePromise) {
            res = await res;
          }
          if (res.errCode && res.errCode !== 'OK') {
            throw {
              body: res,
            };
          }
        } catch (error) {
          err = error;
          if (err.body) {
            err = err.body;
            console.log(err);
          }
        }
        console.log('CallApi Response', res);
        if (!(res instanceof Blob)) {
          if (res.errCode && res.errCode !== 'OK') {
            if (!disabledNotify) {
              notification.error({message: res.errMessage});
            }

            throw new Error(res.errMessage);
          } else if (err !== null) {
            if (err.errCode === '400001') {
              window.location.reload();
            }
            const message = err.errMessage || '未知的錯誤';
            if (!disabledNotify) {
              notification.error({message});
            }
            throw new Error(message);
          }

          if (successMessage) {
            notification.success({message: successMessage});
          }
        }

        if (transform) {
          return transform(res);
        } else {
          return res;
        }
      }
      return fn;
    },
    [disabledNotify, successMessage, errorMessage, memoize]
  );
  return callback;
};
