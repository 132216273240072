import {
  CloseOutlined,
  CopyOutlined,
  DeleteOutlined,
  DownloadOutlined,
  EditOutlined,
  PlusOutlined,
  SettingOutlined,
  UploadOutlined,
} from '@ant-design/icons';
import {Button, ButtonProps as AntButtonProps, Tooltip} from 'antd';
import {withProps} from 'recompose';
import {useMemo} from 'react';
import {globalColors, getThemeStyle} from 'libs/colors';
import fp from 'lodash/fp';

export type CustomButtonProps = AntButtonProps & {
  tooltip?: string;
  color?: keyof typeof globalColors.themes;
};

export const CustomButton = ({
  tooltip,
  color,
  style = {},
  disabled,
  ...buttonProps
}: CustomButtonProps) => {
  const btn = (
    <Button
      {...buttonProps}
      style={{...(disabled ? {} : getThemeStyle(color)), ...style}}
      disabled={disabled}
    />
  );
  return tooltip ? <Tooltip title={tooltip}>{btn}</Tooltip> : btn;
};

CustomButton.Add = withProps<CustomButtonProps, CustomButtonProps>(
  fp.merge({
    icon: <PlusOutlined />,
    tooltip: '新增',
    color: 'green',
  })
)(CustomButton);

CustomButton.Edit = withProps<CustomButtonProps, CustomButtonProps>(
  fp.merge({
    icon: <EditOutlined />,
    type: 'primary',
    tooltip: '編輯',
  })
)(CustomButton);

CustomButton.Del = withProps<CustomButtonProps, CustomButtonProps>(
  fp.merge({
    icon: <DeleteOutlined />,
    danger: true,
    type: 'primary',
    tooltip: '刪除',
  })
)(CustomButton);

CustomButton.Copy = withProps<CustomButtonProps, CustomButtonProps>(
  fp.merge({
    icon: <CopyOutlined />,
    type: 'primary',
    tooltip: '複製',
  })
)(CustomButton);

CustomButton.Setting = withProps<CustomButtonProps, CustomButtonProps>(
  fp.merge({
    icon: <SettingOutlined />,
    type: 'primary',
  })
)(CustomButton);

CustomButton.Upload = withProps<CustomButtonProps, CustomButtonProps>(
  fp.merge({
    icon: <UploadOutlined />,
    type: 'primary',
  })
)(CustomButton);

CustomButton.Close = withProps<CustomButtonProps, CustomButtonProps>(
  fp.merge({
    icon: <CloseOutlined />,
    type: 'primary',
    danger: true,
  })
)(CustomButton);

CustomButton.Download = withProps<CustomButtonProps, CustomButtonProps>(
  fp.merge({
    icon: <DownloadOutlined />,
    type: 'primary',
  })
)(CustomButton);
