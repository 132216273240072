import {ModalForm} from '@ant-design/pro-form';
import {
  BackendJkUserFreeLogService,
  FrontendJkUserFreeLogService,
} from '@common/api';
import {Alert, Timeline} from 'antd';
import {CustomButton} from 'components/Common/Button';
import {useApi} from 'hooks/useApi';
import {useAsyncMemo} from 'hooks/useAsync';
import fp from 'lodash/fp';
import moment from 'moment';
import {useMemo, useState} from 'react';

export const JKUserFreeLogTimeline = ({data, userId}: any) => {
  const [isOpen, setIsOpen] = useState(false);
  const findOne = useApi(
    userId
      ? FrontendJkUserFreeLogService.findOne
      : BackendJkUserFreeLogService.findOne
  );
  const apiData = useAsyncMemo(async () => {
    console.log({data});
    if (data.id && isOpen) {
      const res = await findOne({id: data.id});
      return res.data!;
    }
  }, [data.id, isOpen]);
  const timeline = useMemo(() => {
    return fp.pipe(fp.getOr([], 'responseBody.response.timeline'))(
      apiData.result
    );
  }, [apiData.result]);

  return (
    <ModalForm
      title="執行細節"
      trigger={<CustomButton>檢視執行細節</CustomButton>}
      submitter={false}
      onVisibleChange={setIsOpen}
    >
      {timeline.length === 0 ? (
        <Alert message="沒有執行記錄" />
      ) : (
        <Timeline mode="left">
          {timeline.map((x: any) => (
            <Timeline.Item
              label={moment(x.createdAt).format('YYYY/MM/DD HH:mm')}
              color={x.type === 'SUCCESS' ? 'green' : 'red'}
            >
              {x.log}
            </Timeline.Item>
          ))}
        </Timeline>
      )}
    </ModalForm>
  );
};
