import {Button, Result} from 'antd';
import {useAuthorize} from 'core/Authorize';
import React from 'react';
import {BackendVerifyEmail} from './User/VerifyEmail';

export const VerifyEmail: React.FC<{ignore?: boolean}> = ({
  children,
  ignore,
}) => {
  const {user} = useAuthorize<any>();
  if (user.emailVerify || ignore) {
    return <>{children}</>;
  }
  return (
    <Result
      title="請先進行Email驗證才可使用此功能"
      status={'warning'}
      extra={[
        <BackendVerifyEmail>
          <Button>進行驗證</Button>
        </BackendVerifyEmail>,
      ]}
    />
  );
};
